import { Box, Container, Stack, Typography } from "@mui/material";
import GradientHorizontal from "shared/components/GradientHorizontal";

import image1 from "../../../assets/banners/contact-banner.png";

const ContactTopBanner = () => {
  return (
    <Container maxWidth="md" sx={{ pb: 4 }}>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        sx={{ minHeight: "40vh" }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "50%" },
            pr: { xs: 0, sm: 4 },
            boxSizing: "border-box",
          }}
        >
          <Typography variant="h1" sx={{ pb: 2 }}>
            We Will Get in
            <br /> Touch Promptly
          </Typography>
          <GradientHorizontal
            width={"4rem"}
            height={".25rem"}
          ></GradientHorizontal>
          <Typography component="p" sx={{ py: 2 }}>
            Tell us about your organization's needs and we will get in touch
            promptly.
          </Typography>
        </Box>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            width: { xs: "100%", sm: "50%" },
          }}
        >
          <Box
            sx={{ width: "100%", borderRadius: "2rem" }}
            component="img"
            src={image1}
            alt="Contact Banner"
          />
        </Stack>
      </Stack>
    </Container>
  );
};

export default ContactTopBanner;
