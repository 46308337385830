import { Box, Container, Grid, Stack, Typography, Button } from "@mui/material";
import Icon1 from "../../../assets/icons/position.svg";
import Icon2 from "../../../assets/icons/letter.svg";
import GradientVertical from "shared/components/GradientVertical";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { MarketingSendContactFormResult, sendContactForm } from "api";

interface ContactFormProps {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	comments: string;
}

const ContactForm = () => {
	const { enqueueSnackbar } = useSnackbar();

	const { handleSubmit, getValues, control } = useForm<ContactFormProps>({
		mode: "onChange",
		defaultValues: {},
	});

	const onSubmit = handleSubmit(() => {
		return sendContactForm({
			firstName: getValues("firstName"),
			lastName: getValues("lastName"),
			email: getValues("email"),
			comments: getValues("comments"),
		}).then((value) => {
			if ((value as MarketingSendContactFormResult)?.operationStatus?.success) {
				successCallback();
			} else {
				failureCallback();
			}
		});
	});

	function timeout(delay: number) {
		return new Promise((res) => setTimeout(res, delay));
	}

	async function successCallback() {
		enqueueSnackbar("Feedback submitted", {
			variant: "success",
		});
		await timeout(1000); //for 1 sec delay
		window.location.href = "/";
	}

	function failureCallback() {
		enqueueSnackbar("Error while submitting feedback", {
			variant: "error",
		});
	}
	return (
		<Container maxWidth="md">
			<Grid container spacing={0} sx={{ backgroundColor: "#ffffff", borderRadius: "2rem", boxShadow: 4 }}>
				<Grid
					item
					xs={12}
					md={6}
					sx={{
						py: { xs: 4, md: 8 },
						px: { xs: 4, md: 8 },
						backgroundColor: "primary.light",
						borderRadius: { xs: "2rem 2rem 0 0", md: "2rem 0 0 2rem" },
					}}
				>
					<Stack flexDirection={"row"}>
						<Box>
							<GradientVertical width={".25rem"} height={"4rem"} />
						</Box>
						<Box sx={{ pl: 2 }}>
							<Box sx={{ height: "3rem", mb: 2 }} component="img" src={Icon1} alt="Location" />
							<Typography component="p" sx={{ mb: 0 }}>
								Radius, Inc.
							</Typography>
							<Typography variant="h4" sx={{ textTransform: "capitalize" }}>
								530 Washington Blvd
								<br />
								Sea Girt, NJ 08750
							</Typography>
						</Box>
					</Stack>

					<Stack flexDirection={"row"} sx={{ pt: 8 }}>
						<Box>
							<GradientVertical width={".25rem"} height={"4rem"} />
						</Box>
						<Box sx={{ pl: 2 }}>
							<Box sx={{ height: "3rem", mb: 2 }} component="img" src={Icon2} alt="Email" />
							<Typography component="p" sx={{ mb: 0 }}>
								Send us an Inquiry
							</Typography>
							<Typography variant="h4" sx={{ textTransform: "capitalize" }}>
								Questions@RadiusCare.com
							</Typography>
						</Box>
					</Stack>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					sx={{
						py: { xs: 4, md: 8 },
						px: { xs: 4, md: 8 },
						display: "flex",
						alignItems: "center",
					}}
				>
					<Box onSubmit={onSubmit}>
						<Grid
							container
							spacing={2}
							sx={{
								"& .mc-input": {
									width: "100%",
									padding: "1rem 1rem",
									boxSizing: "border-box",
									borderRadius: ".5rem",
									fontSize: "1rem",
									border: "2px solid",
									backgroundColor: "primary.light",
									borderColor: "primary.light",
								},
								"& .mc-input:focus, & .mc-input:hover": {
									borderColor: "secondary.main",
								},
							}}
						>
							<Controller
								name="firstName"
								control={control}
								defaultValue={""}
								rules={{
									required: "Required",
								}}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<Grid item xs={12} sm={6}>
										<Typography sx={{ mb: 0 }}>First Name</Typography>
										<input className="mc-input" type="text" value={value} onChange={onChange} />
									</Grid>
								)}
							/>{" "}
							<Controller
								name="lastName"
								control={control}
								defaultValue={""}
								rules={{
									required: "Required",
								}}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<Grid item xs={12} sm={6}>
										<Typography sx={{ mb: 0 }}>Last Name</Typography>
										<input className="mc-input" type="text" value={value} onChange={onChange} />
									</Grid>
								)}
							/>
							<Controller
								name="email"
								control={control}
								defaultValue={""}
								rules={{
									required: "Required",
								}}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<Grid item xs={12}>
										<Typography sx={{ mb: 0 }}>Email Address</Typography>
										<input className="mc-input" type="email" value={value} onChange={onChange} />
									</Grid>
								)}
							/>
							<Controller
								name="comments"
								control={control}
								defaultValue={""}
								rules={{
									required: "Required",
								}}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<Grid item xs={12}>
										<Typography sx={{ mb: 0 }}>Comments</Typography>
										<textarea className="mc-input" value={value} onChange={onChange} />
									</Grid>
								)}
							/>
						</Grid>
						<Stack flexDirection={"row"} sx={{ pt: 4 }}>
							<Button variant="contained" size="large" type="submit" onClick={onSubmit}>
								Submit
							</Button>
						</Stack>
					</Box>
				</Grid>
			</Grid>
		</Container>
	);
};

export default ContactForm;
