import { Box, Button, Link, Stack, Typography } from "@mui/material";
import GradientVertical from "shared/components/GradientVertical";
import logo from "../../assets/shared/radius-care-logo-white.svg";
import Icon1 from "../../assets/icons/shield.svg";
import InsureanceProviders from "shared/components/InsureanceProviders";
import AppStoreBtn from "shared/components/AppStoreBtns";

const Footer = () => {
	let consumerUrl = "";
	const environment: any = ((globalThis as any)?.["__RUNTIME_CONFIG"] as any)?.ENVIRONMENT_NAME;
	switch (environment) {
		case "Development":
			consumerUrl = "https://consumer.dev.radiuscare.link";
			break;
		case "Staging":
			consumerUrl = "https://consumer.stage.radiuscare.link";
			break;
		case "Production":
			consumerUrl = "https://consumer.radiuscare.com";
			break;
		default:
			consumerUrl = "https://consumer.radiuscare.com";
			break;
	}
	return (
		<Box>
			<InsureanceProviders />
			<Stack flexDirection="row" flexWrap="wrap">
				<Stack
					flexDirection={"row"}
					sx={{
						width: { xs: "100%", md: "50%" },
						justifyContent: "flex-end",
						backgroundColor: "primary.light",
						borderRadius: { xs: "0", md: "0 1rem 0 0" },
					}}
				>
					<Box
						sx={{
							width: { xs: "100%", md: "600px" },
							px: 3,
							py: 8,
							boxSizing: "border-box",
						}}
					>
						<Stack flexDirection={"row"}>
							<Box sx={{ pt: 8 }}>
								<GradientVertical width={".25rem"} height={"4rem"} />
							</Box>
							<Box sx={{ pl: 2 }}>
								<Box sx={{ height: "3rem", mb: 2 }} component="img" src={Icon1} alt={"Safe & Securely"} />
								<Typography variant="h3" sx={{ pb: 2, textTransform: "capitalize" }}>
									Safe & securely
								</Typography>
								<Typography sx={{ mb: 0 }} component="p">
									Your data is stored safe & securely with Radius Care
								</Typography>
								<Button sx={{ mr: 2, display: "none" }} variant="contained" href={consumerUrl + "/signup"} target={"_blank"}>
									Sign Up
								</Button>
								<AppStoreBtn></AppStoreBtn>
							</Box>
						</Stack>
					</Box>
				</Stack>
				<Stack
					sx={{
						width: { xs: "100%", md: "50%" },
						pl: { xs: 0, md: 2 },
						boxSizing: "border-box",
					}}
				>
					<Box
						sx={{
							width: "100%",
							backgroundColor: "primary.xdark",
							borderRadius: { xs: "0", md: "1rem 0 0 0" },
							color: "#ffffff",
							height: "100%",
						}}
					>
						<Box
							sx={{
								width: { xs: "100%", md: "600px" },
								px: 3,
								py: 8,
								boxSizing: "border-box",
								color: "gray.main",
							}}
						>
							<Box
								sx={{
									borderBottom: "2px solid",
									borderColor: "gray.main",
									pb: 2,
									mb: 2,
								}}
							>
								<Box component="img" src={logo} alt="logo" sx={{ maxWidth: "15rem" }} />
							</Box>

							<Box sx={{ "& a": { color: "#ffffff" } }}>
								<Stack flexDirection={"row"}>
									<Typography sx={{ mb: 0, mr: 2 }}>
										<Link underline="none" href="/privacy-policy" target={"_blank"}>
											Privacy Policy
										</Link>
									</Typography>

									<Typography sx={{ mb: 0, mr: 2 }}>
										<Link underline="none" href="/terms-of-service" target={"_blank"}>
											Terms of Service
										</Link>
									</Typography>

									<Typography sx={{ mb: 0, mr: 2 }}>
										<Link href="/sitemap.xml" underline="none" target={"_blank"}>
											Sitemap
										</Link>
									</Typography>
								</Stack>

								<Typography>Copyright © 2022 Radius Care. All Rights Reserved.</Typography>
							</Box>
						</Box>
					</Box>
				</Stack>
			</Stack>
		</Box>
	);
};

export default Footer;
