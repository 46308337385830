import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Helmet } from "react-helmet";
import GradientHorizontal from "shared/components/GradientHorizontal";
import PageContainer from "shared/components/PageContainer";

const AnnDoximity = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>Radius Care | Announcement - Doximity Partnership</title>
      </Helmet>
      <Container maxWidth="sm">
        <Box sx={{ pb: 4 }}>
          <Typography variant="h1" sx={{ pb: 2 }}>
            Radius Care joins #1 medical communication experts Doximity for a
            better healthcare future
          </Typography>
          <GradientHorizontal
            width={"4rem"}
            height={".25rem"}
          ></GradientHorizontal>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 700 }}>By Derek Moore</Typography>
          <Typography>
            Radius Care has partnered with Doximity so that users can get faster
            and easier benefits instantly when using the Radius Care App.
          </Typography>
          <Typography>
            “Doximity shares our vision for a more connected healthcare system
            in America. This makes the new partnership even more special for
            Radius Care users.” Says the founder and CEO of Radius Care, Justin
            Huddy.
          </Typography>
          <Typography>
            Making digital medical communication better is Doximity’s underlying
            mission. As Radius Care continues to create technological advances
            in improving healthcare communication for US citizens, working with
            them is a big win.
          </Typography>
          <Typography>
            Radius Care users can now access over 80% of US doctors directly
            from the app.
          </Typography>
          <Typography>
            The tech bridge between patient and doctor gets more robust with
            this partnership.
          </Typography>
          <Typography>
            You can learn more about Doximity on their website or find out how
            Doximity’s partnership helps users get faster and easier medical
            care when using the Radius Care App on our How it works page.
          </Typography>
        </Box>
      </Container>
    </PageContainer>
  );
};

export default AnnDoximity;
