import { Box, Button, Link, Stack, Typography } from "@mui/material";

import Icon2 from "../../../assets/icons/next-blue.svg";

interface Props {
  image: any;
  title: any;
  date: any;
  path: any;
  target: any;
}

const ArticleCard = ({ image, title, date, path, target }: Props) => {
  return (
    <Stack sx={{ height: "100%" }}>
      <Box
        component="img"
        src={image}
        alt={title}
        sx={{ width: "100%", borderRadius: "2rem" }}
      />
      <Box sx={{ flex: "1" }}>
        <Typography component={"p"} sx={{ color: "gray.main", pt: 2 }}>
          {date}
        </Typography>
        <Link href={path} target={target} underline="hover">
          <Typography variant="h5" sx={{ pb: 2, fontWeight: 400 }}>
            {title}
          </Typography>
        </Link>
      </Box>
      <Stack flexDirection={"row"} justifyContent={"flex-end"}>
        <Button href={path} target={target}>
          <Box
            sx={{ height: "2rem" }}
            component="img"
            src={Icon2}
            alt={"Link Icon"}
          />
        </Button>
      </Stack>
    </Stack>
  );
};

export default ArticleCard;
