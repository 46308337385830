import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Helmet } from "react-helmet";
import GradientHorizontal from "shared/components/GradientHorizontal";
import PageContainer from "shared/components/PageContainer";

const AnnSingleCare = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>Radius Care | Announcement - SingleCare Partnership</title>
      </Helmet>
      <Container maxWidth="sm">
        <Box sx={{ pb: 4 }}>
          <Typography variant="h1" sx={{ pb: 2 }}>
            SingleCare brings big prescription discounts to help Radius Care
            users save money
          </Typography>
          <GradientHorizontal
            width={"4rem"}
            height={".25rem"}
          ></GradientHorizontal>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 700 }}>By Derek Moore</Typography>
          <Typography>
            Radius Care is helping users save money on prescription medicines
            with their latest partnership with SingleCare. Immediate savings can
            be found in the app!
          </Typography>
          <Typography>
            SingleCare offers big discounts by scouring the prices in over
            35,000+ pharmacies nationwide.
          </Typography>
          <Typography>
            Radius Care users can now save up to 80% on their normal price of
            prescriptions by using the new in-app feature.
          </Typography>
          <Typography>
            The partnership means users can compare prices by pharmacy to find
            the best deal on their prescriptions.
          </Typography>
          <Typography>
            One user normally spent $56 a month on just 1 of her prescriptions.
            Finding a local pharmacy that sells the same medicine for $23 took
            just 30 seconds in the Radius Care app. Now she enjoys spending less
            money on medicine and more money on being happy and healthy.
          </Typography>
          <Typography>
            Learn more about Radius Care’s features here. Or to see how
            SingleCare works, visit their website.
          </Typography>
        </Box>
      </Container>
    </PageContainer>
  );
};

export default AnnSingleCare;
