import { Box, Container, Stack, Typography } from "@mui/material";
import GradientHorizontal from "shared/components/GradientHorizontal";

const NewsTopBanner = () => {
	return (
		<Container maxWidth="md" sx={{ pb: 4 }}>
			<Stack flexDirection={"row"} alignItems={"center"} flexWrap={"wrap"} justifyContent={"center"} sx={{ minHeight: "40vh" }}>
				<Box
					sx={{
						width: { xs: "100%", sm: "50%" },
					}}
				>
					<Typography variant="h1" sx={{ pb: 2 }}>
						Radius Care
						<br /> Resources
					</Typography>
					<GradientHorizontal width={"4rem"} height={".25rem"}></GradientHorizontal>
					<Typography component="p" sx={{ py: 2 }}>
						Your one stop shop for all things Radius Care
					</Typography>
				</Box>
				<Stack
					alignItems={"center"}
					justifyContent={"center"}
					sx={{
						width: { xs: "100%", sm: "50%" },
						boxSizing: "border-box",
						pl: { xs: "0", sm: 4 },
					}}
				>
					<Box
						sx={{ width: "100%", borderRadius: "2rem" }}
						component="img"
						src={"./media/images/banners/resource-banner.png"}
						alt="Resources Banner"
					/>
				</Stack>
			</Stack>
		</Container>
	);
};

export default NewsTopBanner;
