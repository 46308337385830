import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import GradientHorizontal from "shared/components/GradientHorizontal";

import aboutImage1 from "../../../assets/about/radius-care-generations-cutout.png";
import aboutImage2 from "../../../assets/about/about-banner-girl.png";

const AboutStats = () => {
  return (
    <Container maxWidth="md">
      <Box
        sx={{
          position: "relative",
          backgroundColor: "primary.main",
          color: "#ffffff",
          borderRadius: "2rem",
          boxSizing: "border-box",
          px: { xs: 4, md: 16 },
          py: { xs: 4, md: 8 },
          mb: { xs: 8, md: 24 },
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "block" },
            position: "absolute",
            bottom: "-150px",
            left: "-150px",
            maxWidth: "250px",
          }}
          component="img"
          src={aboutImage2}
          alt="About Stats Image Girl"
        />
        <Stack flexDirection={"row"} alignItems={"center"} flexWrap={"wrap"}>
          <Stack
            sx={{
              width: { xs: "100%", sm: "50%" },
              order: { xs: "1", sm: "0" },
            }}
          >
            <GradientHorizontal
              width={"4rem"}
              height={".25rem"}
            ></GradientHorizontal>
            <Typography variant="h2" sx={{ mb: 2, mt: 4 }}>
              Make the Best
              <br /> Possible Decisions
            </Typography>

            <Typography component="p" sx={{ mt: 2 }}>
              We’re on our way to achieving our goal of bringing an easier way
              for you to manage your healthcare.
            </Typography>
          </Stack>
          <Stack
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
            sx={{
              width: { xs: "100%", sm: "50%" },
              order: { xs: "0", sm: "1" },
              pb: { xs: 8, sm: 0 },
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "100%" },
                mt: { xs: -40, sm: -60, md: -80 },
              }}
              component="img"
              src={aboutImage1}
              alt="About Banner"
            />
          </Stack>
        </Stack>
        <Grid container spacing={8} sx={{ mt: { xs: 0, sm: 4 } }}>
          <Grid item xs={12} sm={4}>
            <Stack alignItems={"flex-start"}>
              <GradientHorizontal width={"4rem"} height={".25rem"} />
              <Typography component="p" sx={{ mt: 1 }}>
                People That Can Connect
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  fontSize: { xs: "2rem", lg: "2.5rem" },
                  mb: 0,
                }}
              >
                160 Million
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Stack alignItems={"flex-start"}>
              <GradientHorizontal width={"4rem"} height={".25rem"} />
              <Typography component="p" sx={{ mt: 1 }}>
                Diagnoses Details
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  fontSize: { xs: "2rem", lg: "2.5rem" },
                  mb: 0,
                }}
              >
                69,000
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Stack alignItems={"flex-start"}>
              <GradientHorizontal width={"4rem"} height={".25rem"} />
              <Typography component="p" sx={{ mt: 1 }}>
                Doctor Profiles
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  fontSize: { xs: "2rem", lg: "2.5rem" },
                  mb: 0,
                }}
              >
                987,000+
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AboutStats;
