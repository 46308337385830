import { Box, List, ListItem, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Helmet } from "react-helmet";
import GradientHorizontal from "shared/components/GradientHorizontal";
import PageContainer from "shared/components/PageContainer";

const ArtOct2022 = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>
          Radius Care | How to save on your prescription medication costs with
          Radius Care
        </title>
      </Helmet>
      <Container maxWidth="sm">
        <Box sx={{ pb: 4 }}>
          <Typography variant="h1" sx={{ pb: 2 }}>
            How to save on your prescription medication costs with Radius Care
          </Typography>
          <GradientHorizontal
            width={"4rem"}
            height={".25rem"}
          ></GradientHorizontal>
        </Box>
        <Box>
          <Typography>
            The cost of medication rises every year, we know that. But this year
            has seen one of the biggest rises in the past 10 years.
          </Typography>
          <Typography>
            It sometimes feels like one day your medication costs one price then
            the next it’s over 10%-20% more. And you, the consumer, have to pay
            up for it!
          </Typography>
          <Typography>
            According to a 2021 survey by West Health and Gallup, over 18
            million Americans are unable to pay for their prescribed medication
            every month.
          </Typography>
          <Typography>
            So, to help you with your healthcare finances, we’ve compiled a list
            of 5 ways for you to save on your prescription drug costs…
          </Typography>
          <Typography>
            <List sx={{ pl: 6, listStyleType: "decimal" }}>
              <ListItem sx={{ display: "list-item" }}>
                Take your prescribed drugs as instructed
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>Buy in Bulk</ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Talk to your pharmacist
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Check your insurance
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Use the Radius Care App to find the cheapest prices in your area
              </ListItem>
            </List>
          </Typography>
          <Box
            sx={{ width: "100%", borderRadius: "2rem", marginBottom: "1rem" }}
            component="img"
            src={"../media/images/articles/ArtOct2022-image-1.png"}
            alt="Take your prescribed drugs as instructed"
          />
          <Typography sx={{ pb: 2 }} variant="h4" component={"h4"}>
            1 - Take your prescribed drugs as instructed
          </Typography>
          <Typography>
            With costs increasing, some people are cutting their pills in half
            to double the quantity.
          </Typography>
          <Typography>
            And the 2021 West Health and Gallup survey also showed that 10% skip
            prescribed medication doses to save money on their prescriptions.
          </Typography>
          <Typography>
            These little hacks might seem like a good idea now but try telling
            that to your future self-6 months down the line having to pay extra
            medical bills.
          </Typography>
          <Typography>
            The problem here is that by halving your pills, you’re also halving
            your dosage. And if you don’t get the correct dosage, it could
            actually end up costing you more in medical bills further down the
            line.
          </Typography>
          <Typography>Think about…</Typography>
          <Typography>
            <List sx={{ listStyleType: "disc", pl: 6 }}>
              <ListItem sx={{ display: "list-item" }}>ER fees</ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Extra doctor appointments
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Unpaid time off work due to pain or suffering
              </ListItem>
            </List>
          </Typography>
          <Typography>
            It’s important you take the medication your doctor prescribes
            exactly as instructed.
          </Typography>

          <Typography sx={{ pb: 2 }} variant="h4" component={"h4"}>
            2 - Buy in Bulk
          </Typography>
          <Typography>
            Some pharmacies offer special discounts when you buy 3 months'
            supply instead of just 1.
          </Typography>
          <Typography>
            So, if you can afford to spend a little more this month, consider
            stocking up to make savings in future months
          </Typography>
          <Box
            sx={{ width: "100%", borderRadius: "2rem", marginBottom: "1rem" }}
            component="img"
            src={"../media/images/articles/ArtOct2022-image-2.png"}
            alt="Talk to your pharmacist"
          />
          <Typography sx={{ pb: 2 }} variant="h4" component={"h4"}>
            3 - Talk to your pharmacist
          </Typography>
          <Typography>
            Building a good relationship with your pharmacist can work wonders.
            Contrary to popular belief, not all pharmacists are out to empty
            your bank accounts.
          </Typography>
          <Typography>
            Most pharmacists care about you and your wellbeing. So, talk to them
            about your medications, and tell them about your financial concerns.
            They may be able to find a cost-cutting alternative for you. For
            example, sometimes a manufacturer discount program saves you more
            than your insurance co-pay option.
          </Typography>
          <Typography>
            They may also be able to help you with understanding why a drug
            you’ve been prescribed isn’t covered. So instead of just swiping
            your card and paying for the expensive medication, see if there’s an
            opportunity to switch your medication to an alternative that is
            covered.
          </Typography>
          <Typography>
            You can also ask your pharmacist to reach out to the pharmaceutical
            manufacturer of the drug to see if any discount programs are
            available to you.
          </Typography>
          <Typography>
            But you’ll only be able to discover this if you talk to your
            pharmacist.
          </Typography>
          <Typography sx={{ pb: 2 }} variant="h4" component={"h4"}>
            4 - Check your insurance
          </Typography>
          <Typography>
            So, we just looked at talking to your pharmacist if your prescribed
            drug isn’t covered by your insurance. But the only way you’ll know
            that is by checking.
          </Typography>
          <Typography>
            You can find a list of the prescriptions that aren’t covered by your
            insurance on your insurance plan.{" "}
          </Typography>
          <Typography>
            Or you could use the best option and let Radius Care do this for you
            for free…
          </Typography>
          <Typography sx={{ pb: 2 }} variant="h4" component={"h4"}>
            5 - Use the Radius Care App to find the cheapest prices in your area
          </Typography>
          <Typography>
            We’ve saved the best until last. In addition to all the tips we’ve
            given in this article, the free Radius Care App can make finding the
            cheapest medication even easier.
          </Typography>
          <Typography>
            Did you know that different pharmacies charge different prices for
            medication? Not many people do. But by using the Radius Care App you
            can search a radius of nearby pharmacies and get a list of costs in
            seconds.
          </Typography>
          <Typography>
            This screenshot shows exactly how prescription drug cost comparisons
            are displayed in the app.
          </Typography>
          <Typography>
            By linking up your insurance, you’ll be able to see all this
            automatically added to your account without having to spend time
            researching and comparing.
          </Typography>
          <Typography sx={{ pb: 2 }} variant="h4" component={"h4"}>
            Do you want to discover the easiest way to save on your medications?
            All it takes is 3 simple steps…
          </Typography>
          <Typography>
            <List sx={{ pl: 6, listStyleType: "decimal" }}>
              <ListItem sx={{ display: "list-item" }}>
                Download the free App – Right from the App Store
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Create your profile - set up your secure free account in seconds
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Connect your insurance - accept the secure and seamless link
                with your insurer databases
              </ListItem>
            </List>
          </Typography>
          <Typography>
            You can learn more about how the Radius Care app works here.
          </Typography>
        </Box>
      </Container>
    </PageContainer>
  );
};

export default ArtOct2022;
