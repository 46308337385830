import { List, ListItem, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Helmet } from "react-helmet";
import PageContainer from "shared/components/PageContainer";

const PrivacyPolicy = () => {
	return (
		<PageContainer>
			<Helmet>
				<title>Radius Care | Privacy Policy</title>
			</Helmet>
			<Stack justifyContent="center" alignItems="center" sx={{ p: 4 }}>
				<Typography variant="h1">Privacy Policy</Typography>
			</Stack>
			<Container maxWidth="md">
				<Typography>
					<b>Effective Date: September 1, 2022</b>
				</Typography>
				<Typography>
					This Privacy Policy describes the personal information and certain other information that we obtain through the Radius Care (“Radius Care”)
					website (the “Site”) as well as how we use and disclose such information, the means by which we maintain it, and the choices you have about
					the information we collect. Please read this Privacy Policy carefully.
				</Typography>
				<Typography>
					Note that by accessing this Site, you accept the practices and terms set forth in this Privacy Policy. Your use of our Site, and any dispute
					over privacy, is subject to this Policy and our Terms of Services, including its applicable limitations on damages and the resolution of
					disputes. The Radius Care Terms of Services are incorporated by reference into this Policy. If you do not agree to the terms of this Privacy
					Policy, please do not use the Site.
				</Typography>
				<List className="bullet-list">
					<ListItem sx={{ display: "list-item", listStyleType: "number" }}>
						<Typography>
							<strong>Information we collect: </strong>
							When you visit our Site, we may collect or receive personal information about you to operate our business. We collect information
							about you directly from you and from third parties, and automatically through your use of our Site. The types of personal
							information that we may collect include:
						</Typography>
						<Typography>
							<strong>Information You Provide to Us Directly: </strong>
							The information we collect from you depends on how you use our Site:
						</Typography>
						<Typography>
							Correspondence – If you correspond with us via email, we may gather in a file specific to you the information that you submit.
							Additionally, when you voluntarily provide information in free-form text boxes through the Sites or through responses to surveys,
							questionnaires and the like. This includes information provided for support purposes.
						</Typography>
						<Typography>
							<strong>Information We Collect from Other Sources: </strong>
							The information we may receive about you from other sources depends on how you use our Site and the services that we offer. We may
							obtain information about you from affiliates, partners, and other third parties. This information may be used to provide services to
							you and to provide analysis about you in comparison to people who are demographically similar to you. We may combine the information
							we obtain from third parties with information that we have collected about you. For example, if you are an individual for whom we
							access Medicare records, we will receive certain demographic and medical information, including your name, date of birth, clinical
							diagnoses, insurance information, ordering physician name and contact information, treatments, medications, and billing information.
						</Typography>
						<Typography>
							<strong>Information Collected Automatically: </strong>
							We automatically collect information about your use of our Site through cookies, web beacons, and other technologies that operate on
							the Site. These technologies may collect your domain name, internet browser and operating system, the webpages you view on the Site,
							links you click on the Site, your device ID, the length of time you visit our Site, and other interactions with our Site. This
							information may include:
						</Typography>
						<List className="bullet-list">
							<ListItem sx={{ display: "list-item", listStyleType: "circle" }}>
								<Typography>
									<strong>Technical Information:</strong> We collect technical information associated with your activity on our Site including
									information related to your browser and operating system, IP address (the Internet address of your computer), unique device
									identifiers, and other information such as your device type.
								</Typography>
							</ListItem>
							<ListItem sx={{ display: "list-item", listStyleType: "circle" }}>
								<Typography>
									<strong>Site Usage Information:</strong> We collect information to better understand customer traffic patterns and Site
									usage. This may include the webpage that you were visiting before accessing our Site, the pages or features of our Site you
									browsed to inform us which part of our Site you visit and how much time you spend there.
								</Typography>
							</ListItem>
							<ListItem sx={{ display: "list-item", listStyleType: "circle" }}>
								<Typography>
									<strong>Site Preferences and Cookies:</strong> We collect information about your preferences to make your use of the Site
									more productive through the use of cookies. When you visit our Site, whether or not you register for an account, we may send
									one or more cookies to your computer. Cookies remember information about your activities on a website. Please review your
									web browser “Help” file to learn the proper way to modify your cookie settings. However, if you remove cookies, you may not
									have access to certain services or features available on the Site.
								</Typography>
							</ListItem>
							<ListItem sx={{ display: "list-item", listStyleType: "circle" }}>
								<Typography>
									<strong>Analytics:</strong> We use automated devices and applications, such as Google Analytics, to evaluate usage of our
									Site. We also may use other analytic means to evaluate our Site. We use these tools to help us improve our Site’s
									performance and user experiences. These entities may use cookies and other tracking technologies, such as web beacons or
									local storage objects (LSOs), to perform their services. To learn more about Google’s privacy practices, please review the
									Google Privacy Policy at <a href="https:/www.google.com/policies/privacy/">https:/www.google.com/policies/privacy/</a>. You
									can also download the Google Analytics Opt-out Browser Add-on to prevent data from being used by Google Analytics at{" "}
									<a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
								</Typography>
							</ListItem>
							<ListItem sx={{ display: "list-item", listStyleType: "circle" }}>
								<Typography>
									<strong>Do-Not-Track:</strong> Currently, our systems do not recognize browser “do-not-track” requests. You may, however,
									disable certain tracking as discussed in this section (e.g., by disabling cookies).
								</Typography>
							</ListItem>
							<ListItem sx={{ display: "list-item", listStyleType: "circle" }}>
								<Typography>
									<strong>Downloads:</strong> If you download and install certain applications and software we make available, we may receive
									and collect information transmitted from your computing device for the purpose of providing you the relevant Services, such
									as information regarding when you are logged on and available to receive updates or alert notices.
								</Typography>
							</ListItem>
							<ListItem sx={{ display: "list-item", listStyleType: "circle" }}>
								<Typography>
									<strong>Information from Other Sources:</strong> We may obtain information about you from affiliates, partners, and other
									third parties. This information may be used to provide services to you and to provide analysis about you in comparison to
									people who are demographically similar to you. We may combine the information we obtain from third parties with information
									that we have collected about you.
								</Typography>
							</ListItem>
							<ListItem sx={{ display: "list-item", listStyleType: "circle" }}>
								<Typography>
									<strong>Information We Get from Your Use of Services:</strong>
									We may collect information about the Services that you use and how you use them. This information includes: Computer,
									Tablet, or Mobile Telephone information. We may collect device-specific information (such as your hardware model, operating
									system version, unique device identifiers, device sensors and mobile network information including phone number) and device
									sensors and related device hardware. Radius Care Inc. may associate your device identifiers or phone number with your
									Account. We will comply with the usage/license restrictions and requirements applicable to the device from which the
									information comes.
								</Typography>
							</ListItem>
							<ListItem sx={{ display: "list-item", listStyleType: "circle" }}>
								<Typography>
									<strong>Location information:</strong> When you use a location-enabled service, we may collect and process information about
									your actual location, like GPS signals sent by a mobile device. We may also use various technologies to determine location,
									such as sensor data from your device that may, for example, provide information on nearby Wi-Fi access points and cell
									towers.
								</Typography>
							</ListItem>
							<ListItem sx={{ display: "list-item", listStyleType: "circle" }}>
								<Typography>
									<strong>Unique application numbers:</strong> Certain Services include a unique application number. This number and
									information about your installation (for example, the operating system type and application version number) may be sent to
									when you install or uninstall that service or when that service periodically contacts our servers, such as for automatic
									updates.
								</Typography>
							</ListItem>
							<ListItem sx={{ display: "list-item", listStyleType: "circle" }}>
								<Typography>
									<strong>Local storage:</strong> We may collect and store information (including personal information) locally on your device
									using mechanisms such as browser web storage (including HTML 5) and application data caches.
								</Typography>
							</ListItem>
						</List>
					</ListItem>
					<ListItem sx={{ display: "list-item", listStyleType: "number" }}>
						<Typography>
							<strong>How We Use Information We Collect:</strong>
							We may use the information we collect or receive about you for various purposes.
						</Typography>
						<Typography>
							<strong>To Maintain, Improve, and Personalize the Site:</strong>
							We use technical information about your usage of our Site to improve our Site and to provide you and our other customers with a
							better user experience. We may use your Site preferences in aggregate form to help diagnose problems with the Site, and to improve
							and personalize the Site.
						</Typography>
						<Typography>
							<strong>To Communicate with You:</strong>
							If you initiate correspondence with us through the Site or by email, we may also use the information you provide to communicate with
							you, including to address any concerns or questions that you may have about the Site or the services that we have provided to you
							and to remind you of an upcoming appointment.
						</Typography>
						<Typography>
							<strong>For Our Business Purposes:</strong>
							To better understand how users access and use our Site, on an aggregated basis. For example, we will evaluate which features of our
							Site are more (or least) used by users, and we will use this information to make updates to our Site. We may also use information
							for research and analytics purposes. We may use your information for any other purpose disclosed to you at the time we collect or
							receive the information, or otherwise with your consent. We use your information to protect our company and our users. We also use
							your information to protect our Site.
						</Typography>
						<Typography>
							<strong>Legal Obligations/Protecting Our Rights and Interests:</strong>
							We may use information we collect to comply with legal obligations, as part of our general business operations, and for other
							business administration purposes. When necessary, we may use information we collect to investigate, prevent or take action regarding
							illegal activities, suspected fraud, resolving disputes, situations involving potential threats to the safety of any person or
							violations of our Terms of Use or this Policy.
						</Typography>
					</ListItem>
					<ListItem sx={{ display: "list-item", listStyleType: "number" }}>
						<Typography>
							<strong>Sharing of Your Personal Information:</strong>
							We may disclose personal or other information collected about you through this Site in any of the following situations:
						</Typography>
						<Typography>
							<strong>Third Parties:</strong> To the extent necessary to operate our business, we may disclose information that we collect about
							you to our affiliates, and to third-party contractors who perform services for Radius Care in connection with the Site. We currently
							use service providers to assist with IT hosting and IT services and other operational functions. We contractually require these
							affiliates and third parties to keep personal information confidential and use it only for the purposes for which we disclose it to
							them. Our service providers are required to implement physical, administrative and technical safeguards to ensure the ongoing
							confidentiality and security of personal information and to inform us if there is a breach of any personal information.
						</Typography>
						<Typography>
							<strong>In Aggregate:</strong> We may disclose the results of aggregated data about you and other users without restriction.
							Aggregate, anonymized, or de-identified information may be shared with third parties for marketing, advertising, research or similar
							purposes. In these situations, we do not disclose to these entities any information that could be used to personally identify you.
						</Typography>
						<Typography>
							<strong>Corporate Transaction:</strong> We may disclose information about you as part of a merger, acquisition or other sale or
							transfer of all or substantially all of Radius Care’s assets or business. For instance, if (i) we or our affiliates are or may be
							acquired by, merged with, or invested in by another company, or (ii) if any of our assets are or may be transferred to another
							company, whether as part of a bankruptcy or insolvency proceeding or otherwise, we may transfer the information we have collected
							from you to the other company. As part of the business transfer process such as a merger or acquisition, we may share your personal
							information with lenders, auditors, and third-party advisors, including attorneys and consultants.
						</Typography>
						<Typography>
							<strong>When Required by Law:</strong> We may disclose information about you to the government or to third parties under certain
							circumstances when legally obligated to do so. We reserve the right to release information collected through this Site to law
							enforcement, public authorities or other government officials as deemed necessary in our sole discretion.
						</Typography>
						<Typography>
							<strong>With Your Consent:</strong> We may disclose information for any other purpose with your consent with companies,
							organizations or individuals outside of Radius Care, Inc.
						</Typography>
					</ListItem>
					<ListItem sx={{ display: "list-item", listStyleType: "number" }}>
						<Typography>
							<strong>How We Protect Your Information:</strong> Security is very important to us. To help protect the privacy of data we may
							collect through the Site, we employ measures intended to be consistent with industry standard practices for security and encryption
							in data transmission and storage. However, you should keep in mind that the Site is run on software, hardware and networks, any
							component of which may, from time to time, require maintenance or experience problems or breaches of security beyond our control.
							Please also be aware that despite our best efforts to ensure the security of your data, we cannot guarantee that your information
							will be 100% secure.
						</Typography>
						<Typography>
							Please recognize that protecting your personal information is also your responsibility. We urge you to take every precaution to
							protect your information when you are on the Internet, such as using a strong password which is a combination of letters and
							numbers. If you have reason to believe that the security of your account might have been compromised, please let us know
							immediately.
						</Typography>
					</ListItem>
					<ListItem sx={{ display: "list-item", listStyleType: "number" }}>
						<Typography>
							<strong>How Long We Retain Your Information:</strong> Radius Care will retain your personal information only for as long as
							reasonably necessary for the purposes described in this Privacy Policy or as required by law. Please be aware that we may also be
							required to retain your personal information for legal and accounting reasons. In addition, for our records, we may retain original
							and updated information for reasons such as technical constraints, dispute resolution, fraud prevention, troubleshooting and
							agreement enforcement, or in other cases where we reasonably believe that we have a legitimate reason to do so. We retain some
							information in a depersonalized or aggregated form but not in a way that would identify you personally. When you request to delete
							your account, we will either delete all of your data or deidentify the data in form that would not identify you personally.
						</Typography>
					</ListItem>
					<ListItem sx={{ display: "list-item", listStyleType: "number" }}>
						<Typography>
							<strong>Transparency and Your Choices:</strong> You can always opt not to disclose information to us, but keep in mind some
							information may be needed to register with us or to take advantage of some of our features. If you are a registered user of the
							Services, you can modify certain Personal Information or account information by logging in and accessing your account. The
							information you can view, update, and delete may change as the Services change. If you wish to close your account, please email us
							at <a href="mailto:deactivate@radiuscare.com">deactivate@radiuscare.com</a>. Radius Care Inc. will use reasonable efforts to
							promptly delete your account and the related information. Please note, however, that Radius Care Inc. reserves the right to retain
							information from closed accounts, including to comply with law, prevent fraud, resolve disputes, enforce the Agreement and take
							other actions permitted by law. You must promptly notify us if any of your account data is lost, stolen or used without permission.
						</Typography>
					</ListItem>
					<ListItem sx={{ display: "list-item", listStyleType: "number" }}>
						<Typography>
							<strong>Interest Based Advertising:</strong> We may use a type of advertising commonly known as interest-based or online behavioral
							advertising. This means that some of our partners use Tracking Tools, such as cookies, pixel tags, and web beacons, to collect
							information about a user’s online activities to display Radius Care Inc. ads to the user based on the user’s interests (“Behavioral
							Advertising”). Such partners may include third-party service providers, advertisers, advertising networks or platforms, traffic
							measurement service providers, marketing analytics service providers, and other third-party service providers. Other Tracking Tools
							used by our partners may collect information when you use the Services, such as IP address, mobile device ID, operating system,
							browser, web page interactions, geographic location and demographic information, such as gender and age range. These Tracking Tools
							help Radius Care Inc. learn more about our users’ demographics and internet behaviors.
						</Typography>
					</ListItem>
					<ListItem sx={{ display: "list-item", listStyleType: "number" }}>
						<Typography>
							<strong>Children:</strong> This Site are not directed to children under 13. If you learn that your minor child has provided us with
							personal information without your consent, please contact us and we will delete such information from our systems.
						</Typography>
					</ListItem>
					<ListItem sx={{ display: "list-item", listStyleType: "number" }}>
						<Typography>
							<strong>Our Site is Intended for Individuals Within the United States:</strong>
							Our Site is intended for individuals located within the United States. We do not knowingly collect any information from any
							individual located within the European Union or market to individuals residing in the European Union. By accessing the Site from the
							European Union or other regions of the world with laws governing data collection and use that may differ from U.S. law, you are
							consenting to the transfer of your personally identifiable information outside of those regions to the United States and acknowledge
							that you may not have the same protections with respect to your personal information.
						</Typography>
					</ListItem>
					<ListItem sx={{ display: "list-item", listStyleType: "number" }}>
						<Typography>
							<strong>Changes to this Privacy Policy:</strong> Radius Care reserves the right to update or modify this Privacy Policy from time to
							time, with any such changes to be effective prospectively. We will notify you of such changes via e-mail or via a “pop-up” at the
							time of login. You may be asked to affirmatively consent to such changes. Following the notification of any changes, your consent or
							your continued access to the Website or the use of the Website Materials establishes acceptance of those changes.
						</Typography>
					</ListItem>
					<ListItem sx={{ display: "list-item", listStyleType: "number" }}>
						<Typography>
							<strong>Contact Us:</strong> If you have any comments, concerns or questions about this Privacy Policy, please contact us at{" "}
							<a href="mailto:legal@radiuscare.com">legal@radiuscare.com</a> or by mailing to us at 530 Washington Blvd. Sea Girt, NJ 08750.
						</Typography>
					</ListItem>
				</List>
			</Container>
		</PageContainer>
	);
};

export default PrivacyPolicy;
