import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import AccordianContainer from "shared/components/AccordianContainer";
import GradientHorizontal from "shared/components/GradientHorizontal";
import ExpandMoreIcon from "../../../assets/icons/next-blue.svg";

const FAQsYourAccount = () => {
  const [expanded, setExpanded] = React.useState<string | false>();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <AccordianContainer>
      <Box sx={{ pb: 4 }}>
        <Typography variant="h4" sx={{ pb: 2 }}>
          Your Account
        </Typography>
        <GradientHorizontal
          width={"4rem"}
          height={".25rem"}
        ></GradientHorizontal>
      </Box>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            I am a Radius Care member; how do I update my details?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Need to update your personal details? Log in to your account and
            navigate to the profile icon (👤) in the top right-hand corner. In
            this section, you can amend your personal details and any insurance
            details.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            I can’t remember my password, what do I do?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Don’t worry. Simply head over to the login page and click “forgot
            your password” to request a link to reset your password. You should
            receive this email immediately but please allow up to 10 minutes for
            it to arrive and check your spam and junk folders.
          </Typography>
          <Typography>
            After resetting your password, you will be able to continue using
            the app as normal.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ display: "none" }}
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            Can I add family members to my account?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Each member will have their own account in Radius Care. However,
            each member can add Care Collaborator(s), who will get alerts and be
            able to see your Radius Report to help you make the best decision
            for your health. Near or far, your care advocate can be more
            connected to their loved one’s health.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </AccordianContainer>
  );
};

export default FAQsYourAccount;
