import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import Icon1 from "../../../assets/icons/uniform.svg";
import Icon2 from "../../../assets/icons/hospital.svg";
import GradientHorizontal from "shared/components/GradientHorizontal";
import GradientVertical from "shared/components/GradientVertical";

const AppStats = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Grid
        container
        spacing={0}
        sx={{ backgroundColor: "#ffffff", borderRadius: "2rem", boxShadow: 4 }}
      >
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            py: { xs: 4, md: 8 },
            px: { xs: 4, md: 8 },
            backgroundColor: "primary.light",
            borderRadius: { xs: "2rem 2rem 0 0", md: "2rem 0 0 2rem" },
          }}
        >
          <Stack flexDirection={"row"}>
            <Box>
              <GradientVertical width={".25rem"} height={"4rem"} />
            </Box>
            <Box sx={{ pl: 2 }}>
              <Typography variant="h3" sx={{ textTransform: "capitalize" }}>
                Reliable
                <br />
                health facts
              </Typography>
              <Typography component="p" sx={{ mb: 0 }}>
                Radius Care allows you to connect with
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{
            py: { xs: 4, md: 8 },
            px: { xs: 4, md: 8 },
            display: "flex",
            alignItems: "center",
          }}
        >
          <Stack alignItems={"flex-start"}>
            <Box
              sx={{ height: "3rem", mb: 2 }}
              component="img"
              src={Icon1}
              alt="Home Banner"
            />
            <Typography component="p" sx={{ mb: 0 }}>
              Doctors & Physicians
            </Typography>
            <Typography
              component={"span"}
              sx={{
                fontSize: { xs: "3rem", lg: "3.5rem" },
                fontWeight: 700,
                mb: 0,
              }}
            >
              965,000+
            </Typography>
            <GradientHorizontal width={"4rem"} height={".25rem"} />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{
            py: { xs: 4, md: 8 },
            px: { xs: 4, md: 8 },
            display: "flex",
            alignItems: "center",
          }}
        >
          <Stack alignItems={"flex-start"}>
            <Box
              sx={{ height: "3rem", mb: 2 }}
              component="img"
              src={Icon2}
              alt="Home Banner"
            />
            <Typography component="p" sx={{ mb: 0 }}>
              Hospitals & Facilities
            </Typography>
            <Typography
              component={"span"}
              sx={{
                fontSize: { xs: "3rem", lg: "3.5rem" },
                fontWeight: 700,
                mb: 0,
              }}
            >
              6,000+
            </Typography>
            <GradientHorizontal width={"4rem"} height={".25rem"} />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AppStats;
