import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React from "react";
import AccordianContainer from "shared/components/AccordianContainer";
import GradientHorizontal from "shared/components/GradientHorizontal";
import ExpandMoreIcon from "../../../assets/icons/next-blue.svg";

const FAQsGettingStarted = () => {
  const [expanded, setExpanded] = React.useState<string | false>();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <AccordianContainer>
      <Box sx={{ pb: 4 }}>
        <Typography variant="h4" sx={{ pb: 2 }}>
          Getting Started
        </Typography>
        <GradientHorizontal
          width={"4rem"}
          height={".25rem"}
        ></GradientHorizontal>
      </Box>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">What is Radius Care?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Radius Care is the ONLY software that actively monitors your
            individual health data. Radius Care uses your real-time healthcare
            data to show you ways to save money, save time and make smarter
            healthcare decisions. It’s the only advanced healthcare companion in
            existence.
          </Typography>
          <Typography>
            Radius Care provides personalized insights into your health in the
            following areas:
          </Typography>
          <Typography>
            <List sx={{ listStyleType: "disc", pl: 6 }}>
              <ListItem sx={{ display: "list-item" }}>
                Finding the best doctors in your radius
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Comparing healthcare providers
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Medication savings
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Prescription storage and reminders
              </ListItem>

              <ListItem sx={{ display: "list-item" }}>
                Appointment reminders
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Educates you on your specific conditions
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Monitoring healthcare changes
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Easy-to-read health reports
              </ListItem>
            </List>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            How much personal information do I need to provide to set up?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            When you first sign up for Radius Care, you’ll need to create a
            profile. Here you’ll provide standard account details like your
            name, email address, and contact details.
          </Typography>
          <Typography>
            After this, you connect with your insurance and our system
            automatically looks up more than 200 points of data related to your
            health concerns. You don't have to enter any of this information. We
            get it all from over 69,000 possible diagnosis codes from your
            insurance and/or Medicare.
          </Typography>
          <Typography>
            The only information you need to provide is your login to your
            medical insurance via a secure link which is super simple.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">How do I set up my account?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Setting up your account is super easy and totally free.
          </Typography>
          <Typography>
            <List sx={{ listStyleType: "decimal", pl: 6 }}>
              <ListItem sx={{ display: "list-item" }}>
                Create your profile - Set up your secure profile in seconds by
                entering your email address and create a password for Radius
                Care.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Connect your insurance - When prompted, enter your user name and
                password for your medical insurance and/or Medicare.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Improve your healthcare - Access better health choices, prices
                and treatments
              </ListItem>
            </List>
          </Typography>
          <Typography>
            <Link href="/">Click here to set up your free account.</Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            Do I have to connect my insurance?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes. For our advanced Artificial Intelligence to provide
            personalized healthcare insights, you need to connect your
            insurance. This process is easy, fast, and 100% secure.
          </Typography>

          <Typography>
            You won't be able to benefit from all the awesome healthcare tools,
            tips and support Radius Care offers without linking up your
            insurance.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            How much does Radius Care cost (is it really free)?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes! Radius Care is free for members, and it always will be.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </AccordianContainer>
  );
};

export default FAQsGettingStarted;
