import { Box, Stack, Button } from "@mui/material";

const AppStoreBtn = () => {
	return (
		<Stack sx={{ flexDirection: { xs: "column", sm: "row" } }}>
			<Box sx={{ pt: 2 }}>
				<Button sx={{ p: 0 }} variant="text" href="https://apps.apple.com/us/app/radius-care-consumer-health/id1671815630" target="_blank">
					<Box sx={{ height: { xs: "auto", sm: "3.5rem" }, width: { xs: "15rem", sm: "auto" } }} component="img" src={"./media/images/logos/apple-store-btn.svg"} alt="Download On The Apple Store" />
				</Button>
			</Box>
			<Box sx={{ pt: 2, pl: { xs: "0", sm: 1 }, }}>
				<Button sx={{ p: 0 }} variant="text" href="https://play.google.com/store/apps/details?id=com.radiuscare.customerportal" target="_blank">
					<Box sx={{ height: { xs: "auto", sm: "3.5rem" }, width: { xs: "15rem", sm: "auto" } }} component="img" src={"./media/images/logos/google-store-btn.svg"} alt="Get It On Google Play" />
				</Button>
			</Box>
		</Stack>
	);
};

export default AppStoreBtn;
