import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import GradientHorizontal from "shared/components/GradientHorizontal";
import Image1 from "../../../assets/mobileScreens/radius-care-mobile-overview.png";
import Image2 from "../../../assets/mobileScreens/radius-care-mobile-cost.png";
import Image3 from "../../../assets/mobileScreens/radius-care-mobile-achievements.png";
import ConnectButton from "shared/components/ConnectButton";

const HowMobileAppScreens = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Stack alignItems={"center"} sx={{ textAlign: "center", pb: 8 }}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          Radius Care is Simple to Use
        </Typography>
        <GradientHorizontal width={"8rem"} height={".25rem"} />
        <Typography sx={{ mt: 2 }}>And, yes, it really is free!</Typography>
      </Stack>
      <Box
        sx={{
          "& .item:nth-child(odd) .item-bg-color": {
            backgroundColor: "secondary.light",
          },
        }}
      >
        <Grid
          container
          className="item"
          spacing={0}
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "2rem",
            mb: 8,
            border: "2px solid",
            borderColor: "gray.light",
          }}
        >
          <Grid
            item
            className="item-bg-color"
            xs={12}
            md={6}
            sx={{
              backgroundColor: "primary.light",
              borderRadius: { xs: "2rem 2rem 0 0", md: "2rem 0 0 2rem" },
              display: "flex",
              justifyContent: "center",
              p: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
              component="img"
              src={Image1}
              alt=""
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              p: { xs: 2, md: 4 },
              borderLeft: { xs: "none", md: "2px solid" },
              borderLeftColor: { xs: "gray.light", md: "gray.light" },
              borderTop: { xs: "2px solid", md: "none" },
              borderTopColor: { xs: "gray.light", md: "gray.light" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="h3" sx={{ mb: 2 }}>
              360° View of Your Unique
              <br /> Healthcare Footprint
            </Typography>
            <Typography component="p" sx={{ mb: 4 }}>
              From doctors and visits to medications and savings, Radius Care
              gives you a complete picture of your health in seconds.
            </Typography>
            <Box>
              <ConnectButton
                variant={"outlined"}
                size={"large"}
              ></ConnectButton>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          className="item"
          spacing={0}
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "2rem",
            mb: 8,
            border: "2px solid",
            borderColor: "gray.light",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              p: { xs: 2, md: 4 },
              borderRight: { xs: "none", md: "2px solid" },
              borderRightColor: { xs: "gray.light", md: "gray.light" },
              borderTop: { xs: "2px solid", md: "none" },
              borderTopColor: { xs: "gray.light", md: "gray.light" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              order: { xs: 1, md: 0 },
            }}
          >
            <Typography variant="h3" sx={{ mb: 2 }}>
              Every Discount Found
              <br /> so You Save Money
            </Typography>
            <Typography component="p" sx={{ mb: 4 }}>
              Be money smart with Radius Care. Automatically discover cheaper
              medication and care maintenance today.
            </Typography>
            <Box>
              <ConnectButton
                variant={"outlined"}
                size={"large"}
              ></ConnectButton>
            </Box>
          </Grid>
          <Grid
            item
            className="item-bg-color"
            xs={12}
            md={6}
            sx={{
              backgroundColor: "primary.light",
              borderRadius: { xs: "2rem 2rem 0 0", md: "0 2rem 2rem 0" },
              display: "flex",
              justifyContent: "center",
              p: 2,
              order: { xs: 0, md: 1 },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
              component="img"
              src={Image2}
              alt=""
            />
          </Grid>
        </Grid>
        <Grid
          container
          className="item"
          spacing={0}
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "2rem",
            mb: 8,
            border: "2px solid",
            borderColor: "gray.light",
          }}
        >
          <Grid
            item
            className="item-bg-color"
            xs={12}
            md={6}
            sx={{
              backgroundColor: "primary.light",
              borderRadius: { xs: "2rem 2rem 0 0", md: "2rem 0 0 2rem" },
              display: "flex",
              justifyContent: "center",
              p: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
              component="img"
              src={Image3}
              alt=""
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              p: { xs: 2, md: 4 },
              borderLeft: { xs: "none", md: "2px solid" },
              borderLeftColor: { xs: "gray.light", md: "gray.light" },
              borderTop: { xs: "2px solid", md: "none" },
              borderTopColor: { xs: "gray.light", md: "gray.light" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="h3" sx={{ mb: 2 }}>
              Never Miss a Beat
              <br /> with Auto-Reminders
            </Typography>
            <Typography component="p" sx={{ mb: 4 }}>
              Appointments, screenings, medications, follow-ups, and tests. It’s
              so much to stay on top of. Not anymore. Radius Care manages it all
              for you.
            </Typography>
            <Box>
              <ConnectButton
                variant={"outlined"}
                size={"large"}
              ></ConnectButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default HowMobileAppScreens;
