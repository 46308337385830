import { Box, Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import GradientHorizontal from "shared/components/GradientHorizontal";
import ArticleCard from "../components/ArticleCard";

const Articles = () => {
  return (
    <Box>
      <Helmet>
        <title>Radius Care | Articles</title>
      </Helmet>
      <Box sx={{ pb: 4 }}>
        <Typography variant="h3" sx={{ pb: 2 }}>
          Articles
        </Typography>
        <GradientHorizontal
          width={"4rem"}
          height={".25rem"}
        ></GradientHorizontal>
      </Box>
      <Grid spacing={4} container>
        <Grid item xs={12} sm={6} md={4}>
          <ArticleCard
            image={"./media/images/articles/Articles-Nov-1-2022.png"}
            title={"New Diagnosis? What to do next - 3 easy steps"}
            date={"November 1, 2022"}
            target={"_blank"}
            path={"/articles/new-diagnosis-what-to-do-next-3-easy-steps"}
          ></ArticleCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ArticleCard
            image={"./media/images/articles/Articles-Oct-15-2022.png"}
            title={
              "How to save on your prescription medication costs with Radius Care"
            }
            date={"October 15, 2022"}
            target={"_blank"}
            path={
              "/articles/how-to-save-on-your-prescription-medication-costs-with"
            }
          ></ArticleCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Articles;
