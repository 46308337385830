import { Box, Container, Stack, Typography } from "@mui/material";
import ConnectButton from "shared/components/ConnectButton";
import GradientHorizontal from "shared/components/GradientHorizontal";

const AiBanner = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        width: "100%",
        py: 8,
        background:
          "linear-gradient(90deg, rgba(228,229,245,1) 0%, rgba(250,247,240,1) 100%)",
      }}
    >
      <Container maxWidth="md">
        <Stack flexDirection="row" flexWrap={"wrap"}>
          <Stack
            justifyContent={"center"}
            sx={{
              width: { xs: "100%", sm: "50%" },
              order: { xs: "1", sm: "0" },
            }}
          >
            <Box>
              <Typography variant="h2" sx={{ pb: 2 }}>
                Your Advanced
                <br /> Healthcare Companion
              </Typography>
              <GradientHorizontal width={"4rem"} height={".25rem"} />
              <Typography component="p" sx={{ py: 2 }}>
                We trust technology in other areas of our lives. Now it’s time
                to let advanced machine learning optimize your healthcare
                management.
              </Typography>
              <ConnectButton
                variant={"contained"}
                size={"large"}
              ></ConnectButton>
            </Box>
          </Stack>
          <Stack
            justifyContent={"center"}
            sx={{
              width: { xs: "100%", sm: "50%" },
              order: { xs: "0", sm: "1" },
              pb: { xs: 8, sm: 0 },
              pl: { xs: 0, sm: 2 },
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{ width: "100%", borderRadius: "2rem" }}
              component="img"
              src={"./media/images/banners/home-health-companion.png"}
              alt="Home Banner"
            />
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default AiBanner;
