import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  Typography,
} from "@mui/material";
import React from "react";
import AccordianContainer from "shared/components/AccordianContainer";
import GradientHorizontal from "shared/components/GradientHorizontal";
import ExpandMoreIcon from "../../../assets/icons/next-blue.svg";

const FAQsUserGuide = () => {
  const [expanded, setExpanded] = React.useState<string | false>();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <AccordianContainer>
      <Box sx={{ pb: 4 }}>
        <Typography variant="h4" sx={{ pb: 2 }}>
          User Guide
        </Typography>
        <GradientHorizontal
          width={"4rem"}
          height={".25rem"}
        ></GradientHorizontal>
      </Box>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            Where can I find the Radius Care User Guide?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Radius Care has created a step-by-step user guide to help you get
            the most out of your health companion app.
          </Typography>
          <Typography>
            <Link href="./media/documents/radius-care-user-guide.pdf" target="_blank">Click Here for the User Guide.</Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </AccordianContainer>
  );
};

export default FAQsUserGuide;
