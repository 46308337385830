import logo from "../../assets/radius-care-logo.svg";
import { Link } from "react-router-dom";
import { Toolbar, AppBar, Box, Button, IconButton, Stack, Container } from "@mui/material";
import { SearchBar } from "./index";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import NavItems from "./NavItems";

interface Props {
	setThemeMode: any;
	themeMode: string;
	setSearchBar: any;
	searchBar: boolean;
	setSideBar: any;
	sideBar: boolean;
}

const NavBar = ({ setSearchBar, searchBar, setSideBar, sideBar }: Props) => {
	let consumerUrl = "";
	const environment: any = ((globalThis as any)?.["__RUNTIME_CONFIG"] as any)?.ENVIRONMENT_NAME;
	console.log("ENV", environment);
	switch (environment) {
		case "Development":
			consumerUrl = "https://consumer.dev.radiuscare.link";
			break;
		case "Staging":
			consumerUrl = "https://consumer.stage.radiuscare.link";
			break;
		case "Production":
			consumerUrl = "https://consumer.radiuscare.com";
			break;
		default:
			consumerUrl = "https://consumer.radiuscare.com";
			break;
	}
	return (
		<AppBar
			position="sticky"
			sx={{
				pt: { xs: 1, sm: 2 },
				backgroundColor: "transparent",
				position: "fixed",
			}}
			elevation={0}
		>
			<Container maxWidth="lg" sx={{ pl: { xs: 1, sm: 2 }, pr: { xs: 1, sm: 2 } }}>
				<Toolbar
					sx={{
						display: "flex",
						backgroundColor: "background.default",
						height: "5rem",
						boxShadow: 4,
						borderRadius: 10,
						paddingLeft: ".5rem !important",
					}}
				>
					<Stack justifyContent="center" alignItems="center" sx={{ display: { xs: "flex", md: "none" } }}>
						<Box>
							<IconButton onClick={() => setSideBar(!sideBar)}>
								<MenuIcon style={{ color: "grey.dark" }} />
							</IconButton>
						</Box>
						<IconButton sx={{ display: "none" }} onClick={() => setSearchBar(!searchBar)}>
							<SearchIcon style={{ color: "grey.dark" }} />
						</IconButton>
					</Stack>
					<Stack justifyContent="center" alignItems="center" sx={{ "& a": { display: "flex" } }}>
						<Link to="/">
							<Box component="img" src={logo} alt="logo" sx={{ height: { xs: "2.5rem", sm: "4rem" } }} />
						</Link>
					</Stack>
					<Stack
						flexDirection="row"
						sx={{
							position: "absolute",
							right: "0",
							"& .nav-items": {
								pr: 2,
								display: { xs: "none", md: "flex" },
								justifyContent: "center",
								alignItems: "center",
							},
							"& .nav-item": { color: "primary.dark", px: 2 },
						}}
					>
						<NavItems></NavItems>
						<Button
							sx={{
								mr: 1,
							}}
							variant="outlined"
							href={consumerUrl + "/signin"}
							target={"_blank"}
						>
							Sign In
						</Button>
						<Button sx={{ mr: 2 }} variant="contained" href={consumerUrl + "/signup"} target={"_blank"}>
							Sign Up
						</Button>
					</Stack>
				</Toolbar>
			</Container>
			<SearchBar setSearchBar={setSearchBar} searchBar={searchBar} />
		</AppBar>
	);
};

export default NavBar;
