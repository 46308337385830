import { ButtonBase, Typography, Box } from "@mui/material";

const NavItems = () => {
  return (
    <Box className="nav-items">
      <ButtonBase sx={{ display: "none" }} className="nav-item" href="/">
        <Typography
          component={"span"}
          sx={{ mb: 0, textAlign: "center", lineHeight: "1.25" }}
        >
          Home
        </Typography>
      </ButtonBase>
      <ButtonBase className="nav-item" href="/about">
        <Typography
          component={"span"}
          sx={{ mb: 0, textAlign: "center", lineHeight: "1.25" }}
        >
          About
        </Typography>
      </ButtonBase>
      <ButtonBase className="nav-item" href="/how-it-works">
        <Typography
          component={"span"}
          sx={{ mb: 0, textAlign: "center", lineHeight: "1.25" }}
        >
          How It Works
        </Typography>
      </ButtonBase>
      <ButtonBase className="nav-item" href="/FAQs">
        <Typography
          component={"span"}
          sx={{ mb: 0, textAlign: "center", lineHeight: "1.25" }}
        >
          FAQs
        </Typography>
      </ButtonBase>
      <ButtonBase className="nav-item" href="/Resources">
        <Typography
          component={"span"}
          sx={{ mb: 0, textAlign: "center", lineHeight: "1.25" }}
        >
          Resources
        </Typography>
      </ButtonBase>
      <ButtonBase className="nav-item" href="/news">
        <Typography
          component={"span"}
          sx={{ mb: 0, textAlign: "center", lineHeight: "1.25" }}
        >
          News
        </Typography>
      </ButtonBase>

      <ButtonBase className="nav-item" href="/contact">
        <Typography
          component={"span"}
          sx={{ mb: 0, textAlign: "center", lineHeight: "1.25" }}
        >
          Contact
        </Typography>
      </ButtonBase>
    </Box>
  );
};

export default NavItems;
