import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import LogoAetna from "../../assets/insuranceProviders/LogoAetna.svg";
import LogoAnthem from "../../assets/insuranceProviders/LogoAnthem.svg";
import LogoCMS from "../../assets/insuranceProviders/LogoMedicare.svg";
import LogoUnitedHealthcare from "../../assets/insuranceProviders/LogoUnitedHealthcare.svg";
import GradientHorizontal from "shared/components/GradientHorizontal";

const InsureanceProviders = () => {
	return (
		<Container maxWidth="md" sx={{ py: 16 }}>
			<Stack alignItems={"center"} sx={{ textAlign: "center", pb: 8 }}>
				<Typography
					variant="h4"
					sx={{
						textAlign: "center",
						color: "gray.main",
						mb: 2,
					}}
				>
					Now Available to Over 160 Million Americans
				</Typography>
				<GradientHorizontal width={"8rem"} height={".25rem"} />
			</Stack>
			<Grid container spacing={8} justifyContent={"center"} alignItems={"center"}>
				<Grid item xs={12} md={3} sx={{ textAlign: "center" }}>
					<Box sx={{ width: { xs: "12rem", sm: "100%" } }} component="img" src={LogoCMS} alt="logo" />
				</Grid>
				<Grid item xs={12} md={3} sx={{ textAlign: "center" }}>
					<Box sx={{ width: { xs: "12rem", sm: "100%" } }} component="img" src={LogoUnitedHealthcare} alt="logo" />
				</Grid>
				<Grid item xs={12} md={3} sx={{ textAlign: "center" }}>
					<Box sx={{ width: { xs: "12rem", sm: "100%" } }} component="img" src={LogoAetna} alt="logo" />
				</Grid>
				<Grid item xs={12} md={3} sx={{ textAlign: "center" }}>
					<Box sx={{ width: { xs: "12rem", sm: "100%" } }} component="img" src={LogoAnthem} alt="logo" />
				</Grid>
			</Grid>
		</Container>
	);
};

export default InsureanceProviders;
