import { Box } from "@mui/material";

type Props = React.PropsWithChildren<{}>;

function PageContainer({ children }: Props) {
  return (
    <Box
      sx={{
        background:
          "linear-gradient(180deg, rgba(228,229,245,1) 0%, rgba(255,255,255,1) 75%)",
        pt: "8rem",
      }}
    >
      {children}
    </Box>
  );
}

export default PageContainer;
