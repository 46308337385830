import { Box, Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import GradientHorizontal from "shared/components/GradientHorizontal";
import ArticleCard from "../components/ArticleCard";

const InTheNews = () => {
  return (
    <Box>
      <Helmet>
        <title>Radius Care | In the News</title>
      </Helmet>
      <Box sx={{ pb: 4 }}>
        <Typography variant="h3" sx={{ pb: 2 }}>
          In the News
        </Typography>
        <GradientHorizontal
          width={"4rem"}
          height={".25rem"}
        ></GradientHorizontal>
      </Box>
      <Grid spacing={4} container>
        <Grid item xs={12} sm={6} md={4}>
          <ArticleCard
            image={"./media/images/news/News-Aug-24-2022.png"}
            title={
              "The 21st Century Cures Act Part Two: Information Blocking and Interoperability"
            }
            date={"August 24, 2022"}
            target={"_blank"}
            path={
              "https://www.himss.org/resources/21st-century-cures-act-part-two-information-blocking-and-interoperability"
            }
          ></ArticleCard>
        </Grid>{" "}
        <Grid item xs={12} sm={6} md={4}>
          <ArticleCard
            image={"./media/images/news/News-July-14-2021.png"}
            title={"5 Benefits of Using AI in Healthcare"}
            date={"July 14, 2022"}
            target={"_blank"}
            path={
              "https://www.wwt.com/article/5-benefits-of-using-ai-in-healthcare"
            }
          ></ArticleCard>
        </Grid>{" "}
        <Grid item xs={12} sm={6} md={4}>
          <ArticleCard
            image={"./media/images/news/News-July-1-2016.png"}
            title={"4 Ways to Be a Better Patient"}
            date={"July 1, 2016"}
            target={"_blank"}
            path={
              "https://www.scientificamerican.com/article/4-ways-to-be-a-better-patient/"
            }
          ></ArticleCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InTheNews;
