import { Box, Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import GradientHorizontal from "shared/components/GradientHorizontal";
import ArticleCard from "../components/ArticleCard";

import Image1 from "../../../assets/logos/logo-aetna.png";
import Image3 from "../../../assets/logos/logo-doximity.png";
import Image4 from "../../../assets/logos/logo-medicare.png";
import Image5 from "../../../assets/logos/logo-single-care.png";
import Image6 from "../../../assets/logos/logo-united-health.png";

const Announcements = () => {
  return (
    <Box>
      <Helmet>
        <title>Radius Care | Announcements</title>
      </Helmet>
      <Box sx={{ pb: 4 }}>
        <Typography variant="h3" sx={{ pb: 2 }}>
          Announcements
        </Typography>
        <GradientHorizontal
          width={"4rem"}
          height={".25rem"}
        ></GradientHorizontal>
      </Box>
      <Grid spacing={4} container>
        <Grid item xs={12} sm={6} md={4}>
          <ArticleCard
            image={Image4}
            title={
              "Medicare members get instant access to better healthcare with Radius Care"
            }
            date={"April 1, 2022"}
            target={"_blank"}
            path={"/announcements/welcome-medicare"}
          ></ArticleCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ArticleCard
            image={Image1}
            title={
              "Aetna brings better healthcare solutions to their customers with the Radius Care Connection"
            }
            date={"March 15, 2022"}
            target={"_blank"}
            path={"/announcements/welcome-aetna"}
          ></ArticleCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ArticleCard
            image={Image6}
            title={
              "Radius Care is now available to 45 million UnitedHealthcare customers"
            }
            date={"March 1, 2022"}
            target={"_blank"}
            path={"/announcements/welcome-unitedhealth"}
          ></ArticleCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ArticleCard
            image={Image5}
            title={
              "SingleCare brings big prescription discounts to help Radius Care users save money"
            }
            date={"February 15, 2022"}
            target={"_blank"}
            path={"/announcements/singlecare-partnership"}
          ></ArticleCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ArticleCard
            image={Image3}
            title={
              "Radius Care joins #1 medical communication experts Doximity for a better healthcare future"
            }
            date={"February 1, 2022"}
            target={"_blank"}
            path={"/announcements/doximity-partnership"}
          ></ArticleCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Announcements;
