import { Box, Container, Stack, Typography } from "@mui/material";
import GradientHorizontal from "shared/components/GradientHorizontal";
import image1 from "../../../assets/banners/faqs-banner.png";

const FAQsTopBanner = () => {
  return (
    <Container maxWidth="md">
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        sx={{ minHeight: "40vh" }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "50%" },
          }}
        >
          <Typography variant="h1" sx={{ pb: 2 }}>
            How Can We
            <br /> Help You?
          </Typography>
          <GradientHorizontal
            width={"4rem"}
            height={".25rem"}
          ></GradientHorizontal>
          <Typography component="p" sx={{ py: 2 }}>
            Ignorance is not bliss. Find answers to your questions using the
            categories below.
          </Typography>
        </Box>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            width: { xs: "100%", sm: "50%" },
            boxSizing: "border-box",
            pl: { xs: "0", sm: 4 },
          }}
        >
          <Box
            sx={{ width: "100%", borderRadius: "2rem" }}
            component="img"
            src={image1}
            alt="How It Works Banner"
          />
        </Stack>
      </Stack>
    </Container>
  );
};

export default FAQsTopBanner;
