import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import GradientHorizontal from "shared/components/GradientHorizontal";
import IconLink from "shared/components/IconLink";

const ResourcesList = () => {
	return (
		<Box>
			<Stack
				alignItems="center"
				justifyContent="center"
				sx={{
					width: "100%",
					py: 8,
					background: "linear-gradient(90deg, rgba(228,229,245,1) 0%, rgba(250,247,240,1) 100%)",
				}}
			>
				<Container maxWidth="md">
					<Box sx={{ pb: 4 }}>
						<Typography variant="h3" sx={{ pb: 2 }}>
							Resources
						</Typography>
						<GradientHorizontal width={"4rem"} height={".25rem"}></GradientHorizontal>
					</Box>
					<Grid container spacing={4} alignItems="center" justifyContent={"center"}>
						<Grid item xs={12} sm={6} md={4}>
							<IconLink title={"Free Resources"} path={"https://my.radiuscare.com/l/1019302/2023-04-03/33j2"} target={"_blank"}></IconLink>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<IconLink title={"User Guide"} path={"./media/documents/radius-care-user-guide.pdf"} target={"_blank"}></IconLink>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<IconLink title={"White Papers"} path={"./media/documents/radius-care-consumer-healthcare.pdf"} target={"_blank"}></IconLink>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<IconLink title={"Case Studies"} path={undefined}></IconLink>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<IconLink title={"Infographics"} path={"./media/documents/radius-care-healthcare-journey.pdf"} target={"_blank"}></IconLink>
						</Grid>
					</Grid>
				</Container>
			</Stack>
		</Box>
	);
};

export default ResourcesList;
