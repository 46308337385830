import { Box, Container, Stack, Typography } from "@mui/material";
import GradientHorizontal from "shared/components/GradientHorizontal";

const AboutBanner = () => {
  return (
    <Container maxWidth="md">
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        flexWrap={"wrap"}
        sx={{ minHeight: { xs: "24rem", md: "32rem" }, pb: { xs: 32, sm: 0 } }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "50%", md: "50%" },
            order: { xs: "1", sm: "0" },
          }}
        >
          <Typography variant="h1" sx={{ mb: 2 }}>
            Changing the World
            <br /> of Healthcare.
          </Typography>

          <GradientHorizontal
            width={"4rem"}
            height={".25rem"}
          ></GradientHorizontal>
          <Typography component="p" sx={{ py: 2 }}>
            Helping you live a healthier life. Managing your healthcare is easy
            with the right tools.
          </Typography>
        </Box>
      </Stack>
    </Container>
  );
};

export default AboutBanner;
