import { Box, Container, Grid, Typography } from "@mui/material";
import GradientHorizontal from "shared/components/GradientHorizontal";

import missionImage1 from "../../../assets/about/about-image-runner.png";
import missionImage2 from "../../../assets/about/about-image-ai.png";
import missionImage3 from "../../../assets/about/about-image-meditating.png";
import ConnectButton from "shared/components/ConnectButton";

const AboutMission = () => {
  return (
    <Container maxWidth="md">
      <Box>
        <GradientHorizontal width={"4rem"} height={".25rem"} />
        <Typography sx={{ color: "gray.main", mt: 2 }}>
          Radius Care - Our Mission
        </Typography>
        <Typography variant="h2" sx={{ mb: 12 }}>
          Engage and Assist People in Their
          <br /> Health Journey
        </Typography>
      </Box>
      <Grid
        container
        className="item"
        spacing={0}
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "2rem",
          mb: 8,
          border: "2px solid",
          borderColor: "gray.light",
        }}
      >
        <Grid
          item
          className="item-bg-color"
          xs={12}
          sm={6}
          sx={{
            borderRadius: { xs: "2rem 2rem 0 0", sm: "2rem 0 0 2rem" },
            display: "flex",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              width: { xs: "auto" },
            }}
            component="img"
            src={missionImage1}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            p: { xs: 2, md: 4 },
            borderLeft: { xs: "none", sm: "2px solid" },
            borderLeftColor: { xs: "gray.light", sm: "gray.light" },
            borderTop: { xs: "2px solid", sm: "none" },
            borderTopColor: { xs: "gray.light", sm: "gray.light" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h3" sx={{ mb: 2 }}>
            Your Health
            <br /> Your Happiness
          </Typography>
          <Typography component="p" sx={{ mb: 4 }}>
            It’s always been about you. Our dream was clear. Give you the tools
            to live a longer, happier life. We use technology to power your
            healthcare choices. We're building a better way to access your
            healthcare.
          </Typography>
          <Box>
            <ConnectButton variant={"outlined"} size={"large"}></ConnectButton>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        className="item"
        spacing={0}
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "2rem",
          mb: 8,
          border: "2px solid",
          borderColor: "gray.light",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            p: { xs: 2, md: 4 },
            borderRight: { xs: "none", sm: "2px solid" },
            borderRightColor: { xs: "gray.light", sm: "gray.light" },
            borderTop: { xs: "2px solid", sm: "none" },
            borderTopColor: { xs: "gray.light", sm: "gray.light" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            order: { xs: 1, md: 0 },
          }}
        >
          <Typography variant="h3" sx={{ mb: 2 }}>
            Visionary Tools Using
            <br />
            Advanced Technology
          </Typography>
          <Typography component="p" sx={{ mb: 4 }}>
            Our tools are built with you in mind. Optimized to help you
            understand, engage, and activate. Your healthcare companion is a
            clever AI that uses machine learning. It empowers you to achieve
            your best possible health results.
          </Typography>
          <Box>
            <ConnectButton variant={"outlined"} size={"large"}></ConnectButton>
          </Box>
        </Grid>
        <Grid
          item
          className="item-bg-color"
          xs={12}
          sm={6}
          sx={{
            borderRadius: { xs: "2rem 2rem 0 0", sm: "0 2rem 2rem 0" },
            display: "flex",
            justifyContent: "center",
            overflow: "hidden",
            order: { xs: 0, md: 1 },
          }}
        >
          <Box
            sx={{
              width: { xs: "auto" },
            }}
            component="img"
            src={missionImage2}
          />
        </Grid>
      </Grid>
      <Grid
        container
        className="item"
        spacing={0}
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "2rem",
          mb: 8,
          border: "2px solid",
          borderColor: "gray.light",
        }}
      >
        <Grid
          item
          className="item-bg-color"
          xs={12}
          sm={6}
          sx={{
            borderRadius: { xs: "2rem 2rem 0 0", sm: "2rem 0 0 2rem" },
            display: "flex",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              width: { xs: "auto" },
            }}
            component="img"
            src={missionImage3}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            p: { xs: 2, md: 4 },
            borderLeft: { xs: "none", sm: "2px solid" },
            borderLeftColor: { xs: "gray.light", sm: "gray.light" },
            borderTop: { xs: "2px solid", sm: "none" },
            borderTopColor: { xs: "gray.light", sm: "gray.light" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h3" sx={{ mb: 2 }}>
            Harmonizing Your Health with the Right Information
          </Typography>
          <Typography component="p" sx={{ mb: 4 }}>
            Our Mission - To engage people in their healthcare journey. To
            securely track their unique health data. To encourage them to take
            action on information when their health changes. To help everyone
            achieve ultimate healthcare harmony.
          </Typography>
          <Box>
            <ConnectButton variant={"outlined"} size={"large"}></ConnectButton>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutMission;
