import { Box, Container, Stack, Typography } from "@mui/material";
import ConnectButton from "shared/components/ConnectButton";
import GradientHorizontal from "shared/components/GradientHorizontal";

import image1 from "../../../assets/shared/radius-care-women-phone-app-cutout.png";
import AppStoreBtn from "shared/components/AppStoreBtns";

const HowTopBanner = () => {
  return (
    <Container maxWidth="md">
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        sx={{ minHeight: "40vh" }}
      >
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            width: { xs: "100%", sm: "50%" },
            boxSizing: "border-box",
            pr: { xs: "0", sm: 4 },
          }}
        >
          <Box
            sx={{ width: "100%" }}
            component="img"
            src={image1}
            alt="How It Works Banner"
          />
        </Stack>
        <Box
          sx={{
            width: { xs: "100%", sm: "50%" },
          }}
        >
          <Typography variant="h1" sx={{ pb: 2 }}>
            How to Get Started
            <br /> with Radius Care
          </Typography>
          <GradientHorizontal
            width={"4rem"}
            height={".25rem"}
          ></GradientHorizontal>
          <Typography component="p" sx={{ py: 2 }}>
            Radius Care is your complete healthcare companion in your pocket.
            Access your unique health report in our app today.
          </Typography>
          <ConnectButton variant={"contained"} size={"large"}></ConnectButton>
          <Box sx={{ pb: 2 }}>
						<AppStoreBtn></AppStoreBtn>
					</Box>
        </Box>
      </Stack>
    </Container>
  );
};

export default HowTopBanner;
