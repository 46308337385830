/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useMutation
} from '@tanstack/react-query'
import type {
  UseMutationOptions,
  MutationFunction
} from '@tanstack/react-query'
import type {
  MarketingSendContactFormResult,
  SendContactFormParams
} from '../backend.schemas'
import { apiRequest } from '.././client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const sendContactForm = (
    params?: SendContactFormParams,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<MarketingSendContactFormResult>(
      {url: `/api/consumer/ConsumerMarketing/SendContactForm`, method: 'post',
        params
    },
      options);
    }
  


    export type SendContactFormMutationResult = NonNullable<Awaited<ReturnType<typeof sendContactForm>>>
    
    export type SendContactFormMutationError = unknown

    export const useSendContactForm = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendContactForm>>, TError,{params?: SendContactFormParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendContactForm>>, {params?: SendContactFormParams}> = (props) => {
          const {params} = props ?? {};

          return  sendContactForm(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof sendContactForm>>, TError, {params?: SendContactFormParams}, TContext>(mutationFn, mutationOptions)
    }
    