import { Box } from "@mui/material";
import { Helmet } from "react-helmet";
import PageContainer from "shared/components/PageContainer";
import HowItWorksVideoBanner from "./components/HowItWorksVideoBanner";
import HowMobileAppScreens from "./components/HowMobileAppScreens";
import HowTopBanner from "./components/HowTopBanner";
import Steps from "./components/Steps";

const HowItWorks = () => {
  return (
    <Box>
      <PageContainer>
        <Helmet>
          <title>Radius Care | How It Works</title>
        </Helmet>
        <HowTopBanner></HowTopBanner>
        <Steps></Steps>
      </PageContainer>
      <HowItWorksVideoBanner></HowItWorksVideoBanner>
      <Box
        sx={{
          background:
            "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(228,229,245,1) 75%)",
          pt: "7rem",
        }}
      >
        <HowMobileAppScreens></HowMobileAppScreens>
      </Box>
    </Box>
  );
};

export default HowItWorks;
