import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React from "react";
import AccordianContainer from "shared/components/AccordianContainer";
import GradientHorizontal from "shared/components/GradientHorizontal";
import ExpandMoreIcon from "../../../assets/icons/next-blue.svg";

const FAQsUsingRadius = () => {
  const [expanded, setExpanded] = React.useState<string | false>();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <AccordianContainer>
      <Box sx={{ pb: 4 }}>
        <Typography variant="h4" sx={{ pb: 2 }}>
          Using Radius Care
        </Typography>
        <GradientHorizontal
          width={"4rem"}
          height={".25rem"}
        ></GradientHorizontal>
      </Box>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            How does Radius Care provide personalized health insights?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            After you’ve set up your account and linked your insurance, Radius
            Care continuously monitors your health data for changes.
          </Typography>

          <Typography>
            This process is similar to credit monitoring. Anytime you get a
            diagnosed health change, Radius Care sees it and automatically adds
            it to your account.
          </Typography>

          <Typography>
            You’ll get an alert , based on your preferences, within 24 hours to
            remind you to view your Radius Report™. Your unique and personalized
            report provides data-driven insights and objective recommendations
            specific to your health change.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            What is my Radius Report™ and where do I find it?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Your Radius Report™ is an entire overview of your healthcare in one
            place. It provides recommendations unique to you and your health
            data.
          </Typography>
          <Typography>
            You can find your Radius Report™ on the navigation menu on the
            sidebar of the desktop app or in the more menu on the mobile device
            app.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            What is the Total Care Score and what does it mean?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can find your Total Care Score on your dashboard under the My
            Overview tab. Your score is based on
          </Typography>
          <Typography>
            <List sx={{ listStyleType: "disc", pl: 6 }}>
              <ListItem sx={{ display: "list-item" }}>
                The ratings of your current doctors
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                The ratings of hospitals you visit
              </ListItem>
            </List>
          </Typography>
          <Typography>
            A higher Total Care Score means you’re receiving better health
            services. If your score is lower, check the recommendations for ways
            you can get better healthcare in your radius to help you recover
            faster.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            How do I improve my Total Care Score?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can improve your Total Care Score by following the
            recommendations provided by Radius Care. The Radius Care AI shows
            unique insights for improving your health.
          </Typography>
          <Typography>
            Compare doctors, hospitals, and health services in the app to see if
            better-rated healthcare is available within your radius. If it is,
            you can switch to healthcare providers that specialize in your
            condition(s). This will increase your Total Care Score.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            Can Radius Care help me save money?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes! Radius Care automatically searches pharmacy prices within your
            chosen radius and shows you where you can get your prescriptions and
            medications for less.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            Is there a guide for how to get the best out of Radius Care?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, there is. We’ve created a user guide that runs you through
            everything you can do with Radius Care. Use this guide to get clear
            instructions on every functionality of Radius Care.
          </Typography>
          <Typography>
            <Link href="./media/documents/radius-care-user-guide.pdf" target="_blank">Click here to access the user guide.</Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </AccordianContainer>
  );
};

export default FAQsUsingRadius;
