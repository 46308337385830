import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import GradientHorizontal from "shared/components/GradientHorizontal";
import GradientVertical from "shared/components/GradientVertical";

const Steps = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Grid
        container
        spacing={0}
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "2rem",
          boxShadow: 4,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            py: { xs: 4, md: 8 },
            px: { xs: 4, md: 8 },
            backgroundColor: "primary.light",
            borderRadius: { xs: "2rem 2rem 0 0" },
          }}
        >
          <Stack flexDirection={"row"}>
            <Box>
              <GradientVertical width={".25rem"} height={"4rem"} />
            </Box>
            <Box sx={{ pl: 2 }}>
              <Typography variant="h3">It's Easy to Set Up</Typography>
              <Typography component="p" sx={{ mb: 0 }}>
                3 steps to a healthy, happy, harmonious you
              </Typography>
            </Box>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            py: { xs: 4, md: 8 },
            px: 4,
            display: "flex",
          }}
        >
          <Stack alignItems={"flex-start"} flexDirection="row">
            <Box>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"row"}
                sx={{
                  width: "3rem",
                  height: "3rem",
                  borderRadius: "3rem",
                  border: "2px solid",
                  borderColor: "gray.light",
                }}
              >
                <Typography component={"span"} sx={{ mb: 0, fontSize: "2rem" }}>
                  1
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ pl: 2 }}>
              <Typography variant="h4" component="h4" sx={{ mb: 1 }}>
                Create Your
                <br /> Profile
              </Typography>
              <GradientHorizontal width={"4rem"} height={".25rem"} />
              <Typography component="p" sx={{ mt: 1 }}>
                Set up your secure free account in seconds
              </Typography>
            </Box>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            py: { xs: 4, md: 8 },
            px: 4,
            display: "flex",
          }}
        >
          <Stack alignItems={"flex-start"} flexDirection="row">
            <Box>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"row"}
                sx={{
                  width: "3rem",
                  height: "3rem",
                  borderRadius: "3rem",
                  border: "2px solid",
                  borderColor: "gray.light",
                }}
              >
                <Typography component={"span"} sx={{ mb: 0, fontSize: "2rem" }}>
                  2
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ pl: 2 }}>
              <Typography variant="h4" component="h4" sx={{ mb: 1 }}>
                Connect Your
                <br /> Insurance
              </Typography>
              <GradientHorizontal width={"4rem"} height={".25rem"} />
              <Typography component="p" sx={{ mt: 1 }}>
                Accept secure and seamless link with your insurer databases
              </Typography>
            </Box>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            py: { xs: 4, md: 8 },
            px: 4,
            display: "flex",
          }}
        >
          <Stack alignItems={"flex-start"} flexDirection="row">
            <Box>
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"row"}
                sx={{
                  width: "3rem",
                  height: "3rem",
                  borderRadius: "3rem",
                  border: "2px solid",
                  borderColor: "gray.light",
                }}
              >
                <Typography component={"span"} sx={{ mb: 0, fontSize: "2rem" }}>
                  3
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ pl: 2 }}>
              <Typography variant="h4" component="h4" sx={{ mb: 1 }}>
                Improve Your
                <br /> Healthcare
              </Typography>
              <GradientHorizontal width={"4rem"} height={".25rem"} />
              <Typography component="p" sx={{ mt: 1 }}>
                Access better health choices, prices and treatments
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Steps;
