import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import Image1 from "../../../assets/mobileScreens/radius-care-mobile-overview.png";
import Image2 from "../../../assets/mobileScreens/radius-care-mobile-provider-detail.png";
import Image3 from "../../../assets/mobileScreens/radius-care-mobile-calendar.png";
import Image4 from "../../../assets/mobileScreens/radius-care-mobile-new-diagnosis.png";
import GradientHorizontal from "shared/components/GradientHorizontal";

const MobileAppScreens = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Stack alignItems={"center"} sx={{ textAlign: "center", pb: 8 }}>
        <Typography sx={{ color: "gray.main" }}>
          Radius Care - Health
        </Typography>
        <Typography variant="h2" sx={{ mb: 2 }}>
          Healthcare Harmony Delivered with Radius Care
        </Typography>
        <GradientHorizontal width={"8rem"} height={".25rem"} />
      </Stack>
      <Box
        sx={{
          "& .item:nth-child(odd) .item-bg-color": {
            backgroundColor: "secondary.light",
          },
        }}
      >
        <Grid
          container
          className="item"
          spacing={0}
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "2rem",
            mb: 8,
            border: "2px solid",
            borderColor: "gray.light",
          }}
        >
          <Grid
            item
            className="item-bg-color"
            xs={12}
            md={6}
            sx={{
              backgroundColor: "primary.light",
              borderRadius: { xs: "2rem 2rem 0 0", md: "2rem 0 0 2rem" },
              display: "flex",
              justifyContent: "center",
              p: 2,
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "75%", md: "100%" },
              }}
              component="img"
              src={Image1}
              alt=""
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              p: { xs: 2, md: 4 },
              borderLeft: { xs: "none", md: "2px solid" },
              borderLeftColor: { xs: "gray.light", md: "gray.light" },
              borderTop: { xs: "2px solid", md: "none" },
              borderTopColor: { xs: "gray.light", md: "gray.light" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="h3" sx={{ mb: 2 }}>
              All Your Health Needs
              <br /> All in One App
            </Typography>
            <Typography component="p" sx={{ mb: 4 }}>
              View your doctors, conditions, treatments, medications, and new
              diagnoses in one place. Get insights into the best possible care
              choices.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          className="item"
          spacing={0}
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "2rem",
            mb: 8,
            border: "2px solid",
            borderColor: "gray.light",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              p: { xs: 2, md: 4 },
              borderRight: { xs: "none", md: "2px solid" },
              borderRightColor: { xs: "gray.light", md: "gray.light" },
              borderTop: { xs: "2px solid", md: "none" },
              borderTopColor: { xs: "gray.light", md: "gray.light" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              order: { xs: 1, md: 0 },
            }}
          >
            <Typography variant="h3" sx={{ mb: 2 }}>
              The Right Solutions
              <br /> At the Right Time
            </Typography>
            <Typography component="p" sx={{ mb: 4 }}>
              Easily find better healthcare choices recommended by our AI so you
              recover faster.
            </Typography>
          </Grid>
          <Grid
            item
            className="item-bg-color"
            xs={12}
            md={6}
            sx={{
              backgroundColor: "primary.light",
              borderRadius: { xs: "2rem 2rem 0 0", md: "0 2rem 2rem 0" },
              display: "flex",
              justifyContent: "center",
              p: 2,
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "75%", md: "100%" },

                order: { xs: 0, md: 1 },
              }}
              component="img"
              src={Image2}
              alt=""
            />
          </Grid>
        </Grid>
        <Grid
          container
          className="item"
          spacing={0}
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "2rem",
            mb: 8,
            border: "2px solid",
            borderColor: "gray.light",
          }}
        >
          <Grid
            item
            className="item-bg-color"
            xs={12}
            md={6}
            sx={{
              backgroundColor: "primary.light",
              borderRadius: { xs: "2rem 2rem 0 0", md: "2rem 0 0 2rem" },
              display: "flex",
              justifyContent: "center",
              p: 2,
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "75%", md: "100%" },
              }}
              component="img"
              src={Image3}
              alt=""
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              p: { xs: 2, md: 4 },
              borderLeft: { xs: "none", md: "2px solid" },
              borderLeftColor: { xs: "gray.light", md: "gray.light" },
              borderTop: { xs: "2px solid", md: "none" },
              borderTopColor: { xs: "gray.light", md: "gray.light" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="h3" sx={{ mb: 2 }}>
              Live Longer
              <br /> Live Happier
            </Typography>
            <Typography component="p" sx={{ mb: 4 }}>
              Stay on track with auto-reminders about upcoming appointments and
              suggested screenings.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          className="item"
          spacing={0}
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "2rem",
            mb: 8,
            border: "2px solid",
            borderColor: "gray.light",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              p: { xs: 2, md: 4 },
              borderRight: { xs: "none", md: "2px solid" },
              borderRightColor: { xs: "gray.light", md: "gray.light" },
              borderTop: { xs: "2px solid", md: "none" },
              borderTopColor: { xs: "gray.light", md: "gray.light" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              order: { xs: 1, md: 0 },
            }}
          >
            <Typography variant="h3" sx={{ mb: 2 }}>
              Saving You Money
              <br /> Every Day
            </Typography>
            <Typography component="p" sx={{ mb: 4 }}>
              Instant alerts on savings put your money back in your pocket -
              right where it belongs.
            </Typography>
          </Grid>
          <Grid
            item
            className="item-bg-color"
            xs={12}
            md={6}
            sx={{
              backgroundColor: "primary.light",
              borderRadius: { xs: "2rem 2rem 0 0", md: "0 2rem 2rem 0" },
              display: "flex",
              justifyContent: "center",
              p: 2,
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "75%", md: "100%" },
                order: { xs: 0, md: 1 },
              }}
              component="img"
              src={Image4}
              alt=""
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default MobileAppScreens;
