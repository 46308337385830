import { Helmet } from "react-helmet";
import ContactTopBanner from "./components/ContactTopBanner";
import ContactForm from "./components/ContactForm";
import PageContainer from "shared/components/PageContainer";

const Contact = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>Radius Care | Contact Us</title>
      </Helmet>

      <ContactTopBanner></ContactTopBanner>
      <ContactForm></ContactForm>
    </PageContainer>
  );
};

export default Contact;
