import { Box, Container, Stack, Typography } from "@mui/material";
import GradientHorizontal from "shared/components/GradientHorizontal";

import wordCloud from "../../../assets/about/about-image-teamwork.png";

const AboutValues = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        width: "100%",
        py: 8,
        background:
          "linear-gradient(90deg, rgba(228,229,245,1) 0%, rgba(250,247,240,1) 100%)",
      }}
    >
      <Container maxWidth="md">
        <Stack flexDirection="row" flexWrap={"wrap"}>
          {" "}
          <Stack
            justifyContent={"center"}
            sx={{
              width: { xs: "100%", sm: "50%", md: "40%" },
            }}
          >
            <Box>
              <Typography variant="h2" sx={{ pb: 2 }}>
                Our Values
              </Typography>
              <GradientHorizontal width={"4rem"} height={".25rem"} />
              <Typography component="p" sx={{ mt: 2 }}>
                Our values guide every decision we make. You are at the center
                of everything because we know every decision impacts your
                healthcare journey.
              </Typography>
            </Box>
          </Stack>
          <Stack
            justifyContent={"center"}
            sx={{
              boxSizing: "border-box",
              width: { xs: "100%", sm: "50%", md: "60%" },
              pb: { xs: 8, sm: 0 },
              pl: { xs: 0, sm: 4 },
            }}
          >
            <Box
              sx={{ width: "100%", borderRadius: "2rem" }}
              component="img"
              src={wordCloud}
              alt="Word Cloud"
            />
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default AboutValues;
