import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  Typography,
} from "@mui/material";
import React from "react";
import AccordianContainer from "shared/components/AccordianContainer";
import GradientHorizontal from "shared/components/GradientHorizontal";
import ExpandMoreIcon from "../../../assets/icons/next-blue.svg";

const FAQsFixProblem = () => {
  const [expanded, setExpanded] = React.useState<string | false>();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <AccordianContainer>
      <Box sx={{ pb: 4 }}>
        <Typography variant="h4" sx={{ pb: 2 }}>
          Fix a Problem
        </Typography>
        <GradientHorizontal
          width={"4rem"}
          height={".25rem"}
        ></GradientHorizontal>
      </Box>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            I’ve requested a password reset but haven’t received an email
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Hold tight. We send out password reset emails immediately but there
            could be something slowing the delivery down. Make sure you’ve
            checked your junk or spam folders in case the email ended up in
            there. Please wait 5 minutes before requesting another password
            reset.
          </Typography>
          <Typography>
            <Link href="/Contact">
              If your problem persists, please contact our support team using
              this online form.
            </Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">The app is not loading</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We do our best to keep our systems up and running at all times.
            There may be times when the app is being updated and this can slow
            the processor down.
          </Typography>
          <Typography>
            You should also check that the app is updated on your device to the
            latest version. Old versions may no longer be supported.
          </Typography>
          <Typography>
            You can update your app by visiting the app store on apple devices
            or the play store on android devices.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            My new diagnosis isn’t showing up in the app
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We gather new diagnoses immediately from your medical records. But
            it can take up to 24 hours to appear in the app.
          </Typography>
          <Typography>
            If it’s been more than 24 hours since your diagnosis, please reach
            out to our support team below for further assistance.
          </Typography>
          <Typography>
            <Link href="/Contact">
              Contact our support team using this online form.
            </Link>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">I’m locked out of my account</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To increase the security of your account you will automatically be
            locked out if you input a wrong password more than 3 consecutive
            times. Your account will automatically unlock after 10 minutes.
          </Typography>
          <Typography>
            If you’ve forgotten your password, please request a new one using
            the “forgot your password” link on the sign-in page.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            My insurance connection is not working
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Please make sure you have entered your insurance information
            correctly into the system. Go to your insurance website and log in
            to ensure it is correct.
          </Typography>
          <Typography>
            <Link href="/Contact">
              If your problem persists, please contact our support team using
              this online form.
            </Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </AccordianContainer>
  );
};

export default FAQsFixProblem;
