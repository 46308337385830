import { Box, List, ListItem, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Helmet } from "react-helmet";
import GradientHorizontal from "shared/components/GradientHorizontal";
import PageContainer from "shared/components/PageContainer";

const ArtNov2022 = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>
          Radius Care | New Diagnosis? What to do next - 3 easy steps
        </title>
      </Helmet>
      <Container maxWidth="sm">
        <Box sx={{ pb: 4 }}>
          <Typography variant="h1" sx={{ pb: 2 }}>
            New Diagnosis? What to do next - 3 easy steps
          </Typography>
          <GradientHorizontal
            width={"4rem"}
            height={".25rem"}
          ></GradientHorizontal>
        </Box>
        <Box>
          <Typography>
            The average adult visits their doctor 2-3 times per year. For those
            with existing health conditions, it will be many more.
          </Typography>
          <Typography>
            When we visit our doctors for a check-up we hope to come out of the
            office with a clean bill of health. But what do you do if something
            comes up? What if the doctor finds a new diagnosis?
          </Typography>
          <Typography>
            Most doctors will quickly tell you information about your new health
            condition and send you on your way with a prescription for new
            medication.
          </Typography>
          <Typography>
            As you leave the doctor’s office, you feel all sorts of emotions.
            Worry, fear, denial, , and maybe even sadness. You may be thinking
            “what did I do that caused this?” or “What am I supposed to do now?”
          </Typography>
          <Typography>
            Today, we’ll run you through the best way to process your emotions
            and empower yourself to take control of your healthcare with Radius
            Care.
          </Typography>
          <Typography sx={{ pb: 2 }} variant="h4" component={"h4"}>
            Why is it important to understand your new diagnosis?
          </Typography>
          <Typography>
            Okay. So, your doctor’s given you a new diagnosis. They’ve provided
            the relevant medication. So why do you need to know any more about
            your new condition?
          </Typography>
          <Typography>
            So many people choose ignorance when it comes to their healthcare.
            They adopt an attitude of “well, the doctor said this, so I have to
            do that”. But Is that really true?
          </Typography>
          <Typography>
            There are lots of factors that could affect the way you handle your
            new condition. Things like…
          </Typography>
          <Typography>
            <List sx={{ listStyleType: "disc", pl: 6 }}>
              <ListItem sx={{ display: "list-item" }}>
                Cost of medication
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Number of follow-up appointments and tests required
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>Recovery times</ListItem>
              <ListItem sx={{ display: "list-item" }}>Diet</ListItem>
              <ListItem sx={{ display: "list-item" }}>Lifestyle</ListItem>
              <ListItem sx={{ display: "list-item" }}>Exercise</ListItem>
            </List>
          </Typography>
          <Typography>
            With so much to think about, no wonder so many of us venture blindly
            into our new conditions without giving any thought to them.
          </Typography>
          <Typography>
            But the problem here is that this results in a lack of understanding
            of your condition. This leads to poor execution of medical
            instructions.
          </Typography>
          <Typography>
            The issue is that lack of understanding = lack of recovery.
          </Typography>
          <Typography>
            The best way for you to get healthier is to know about your new
            diagnosis and take control of your healthcare from all angles.
          </Typography>
          <Typography>
            To demonstrate this, we’ll use the example of 56-year-old Monica who
            has just been diagnosed with diabetes and left her doctor's office
            in denial…
          </Typography>
          <Typography sx={{ pb: 2 }} variant="h4" component={"h4"}>
            Don’t ignore your new diagnosis
          </Typography>
          <Typography>
            As she sits in her car in front of the wheel with the engine off,
            she stares at the pieces of paper in her hands. She can't believe
            what the doctor just told her. And she doesn’t want to believe it.
          </Typography>
          <Typography>
            After 5 minutes of complete silence with worrying thoughts creeping
            into her mind. She turns the key and starts her engine. As she
            drives home, the fear of what the doctor said has consumed her mind.
          </Typography>
          <Typography>“How is this going to affect me?”</Typography>
          <Typography>“Will I be able to afford treatment?”</Typography>
          <Typography>
            “Will my friends and colleagues judge my new lifestyle changes?”
          </Typography>
          <Typography>“Why did this happen to me?”</Typography>
          <Typography>
            And when she pulls into her driveway, locks her car, and goes into
            her home, she decides she’ll put this diagnosis behind her. She only
            has $174 in her account, and that’s got to last until the end of the
            month. She can’t afford to take unpaid time off work - there are too
            many bills to pay.
          </Typography>
          <Typography>
            Monica decided the best way to move forward is to put the pamphlets,
            prescriptions, and doctor’s notes at the back of her closet and
            forget about this whole thing.
          </Typography>
          <Typography>
            Skipping ahead a few months, Monica has forgotten all about her
            diagnosis. She’s been tempted to search online about diabetes to
            learn more about it, but that’d just make it all too real.
          </Typography>
          <Typography>
            She thinks there’s no easy way to handle the diagnosis. But what she
            doesn’t know is that as she leaves her diabetes untreated, her
            condition is getting worse.
          </Typography>
          <Typography>
            She’s risking heart disease, kidney failure, possible amputations,
            and blindness. The risk of death is increased too. And all because
            she didn’t have the information or resources, she needed to take
            control of her healthcare.
          </Typography>
          <Typography>
            If Monica had known about Radius Care, this story would’ve been so
            different.
          </Typography>
          <Typography>
            <List sx={{ listStyleType: "disc", pl: 6 }}>
              <ListItem sx={{ display: "list-item" }}>
                She’d have had the facts and resources to increase
                herunderstanding of her new diagnosis and medications
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                She’d have been able to find the most affordable medication
                within her budget.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                She’d have known she was putting her life at risk by choosing to
                do nothing.
              </ListItem>
            </List>
          </Typography>
          <Typography>
            Now, let’s take a look at this exact scenario again, but this time
            we’ll make sure Monica has the Radius Care App downloaded on her
            phone…
          </Typography>
          <Typography sx={{ pb: 2 }} variant="h4" component={"h4"}>
            3 steps to knowledge, awareness, and action with your new diagnosis
          </Typography>
          <Typography>
            56-year-old Monica has just been diagnosed with diabetes and left
            her doctor's office in denial.
          </Typography>
          <Typography>
            As she leaves the doctor’s office she sits in her car and looks at
            the notes her doctor just handed her. It’s all too much to take in
            right now. Then she remembers she has the Radius Care app. She’ll
            take a look when she gets home.
          </Typography>
          <Typography>
            She gets out her phone and sees her new condition already updated in
            her Radius Care account. She sees easy-to-follow information about
            the condition and raises her understanding of diabetes.
          </Typography>
          <Typography>
            She has expert advice at her fingertips for any questions or
            concerns she has.
          </Typography>
          <Typography>
            And she can see the cheapest pharmacy within a 1-mile radius to buy
            her new medication - she can make that work this month.
          </Typography>
          <Typography>
            In this example, Monica was able to take control and find healthcare
            harmony with Radius Care. She bypassed all the worry, fear,
            confusion, and sadness. And moved straight into understanding and
            awareness so she could choose to live a healthier life.
          </Typography>
          <Typography>
            You can do the same. All it takes is 3 simple steps…
          </Typography>
          <Typography>
            <List sx={{ pl: 6, listStyleType: "decimal" }}>
              <ListItem sx={{ display: "list-item" }}>
                Create your profile - set up your secure free account in seconds
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Connect your insurance - accept the secure and seamless link
                with your insurer databases
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Improve your healthcare - Access better health choices, prices,
                and treatments
              </ListItem>
            </List>
          </Typography>
          <Typography>
            You can learn more about how the Radius Care app works here.
          </Typography>
          <Typography>
            Are you ready to get set up? Click here to sign up for free…
          </Typography>
        </Box>
      </Container>
    </PageContainer>
  );
};

export default ArtNov2022;
