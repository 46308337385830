import { Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Helmet } from "react-helmet";
import PageContainer from "shared/components/PageContainer";

const TermsOfService = () => {
	return (
		<PageContainer>
			<Helmet>
				<title>Radius Care | Terms of Service</title>
			</Helmet>
			<Stack justifyContent="center" alignItems="center" sx={{ p: 4 }}>
				<Typography variant="h1">Terms of Service</Typography>
			</Stack>
			<Container maxWidth="md">
				<Typography>
					<b>Effective Date: September 1, 2022</b>
				</Typography>

				<Typography>
					Welcome to the Radius Care Inc. website (this “Website”) provided by Radius Care Operating Company, Inc. (“RadiusCare Inc.,” “we” “our” or
					“us”). Radius Care Inc. gathers information and other data with regard to doctors, hospitals, long-term care facilities and different
					suppliers of medical services administrations (collectively, “Healthcare Providers”) from a collection of sources. Radius Care Inc. utilizes
					proprietary methodologies to choose, gather and change certain information to generate ratings of the Healthcare Providers (“Ratings”). On
					this Website, Radius Care Inc. makes accessible Ratings, records based on explicit criteria, including without impediment, as indexed
					(“Lists”), and other data, profiles and materials with regard to Healthcare Providers (collectively with the Ratings and Lists, the “Website
					Materials”).
				</Typography>

				<Typography>
					Please, read these Terms and Conditions (“Terms and Conditions”) thoroughly.&nbsp; By clicking&nbsp; “I Accept” you acknowledge and agree to
					be bound by these Terms and Conditions and our Privacy Policy. If you don’t consent to these Terms and Conditions, you may&nbsp; not access
					or use the Website in any capacity.&nbsp; In addition, we reserve the right to update, replace or change any part of these Terms and
					Conditions by posting updates and/or changes to our Website, and we will notify you of such changes via e-mail or via a “pop-up” at the time
					of login. You may be asked to affirmatively consent to such changes. Following the notification of any changes, your consent or your
					continued access to the Website or the use of the Website Materials establishes acceptance of those changes.
				</Typography>

				<Typography>
					Radius Care Inc. isn’t a referral service and doesn’t suggest or underwrite a specific Healthcare Provider. Radius Care Inc. is a repository
					of information that provides selected data about Healthcare Providers. We don’t offer counsel with respect to the quality or reasonableness
					of a specific Healthcare Provider for explicit treatments or health conditions, and no information on this Website ought to be translated as
					health or medical guidance. THIS WEBSITE AND WEBSITE MATERIALS DO NOT CONSTITUTE MEDICAL ADVICE. The Ratings and Healthcare Provider data
					comprises of statements of opinion and not explanations of reality or proposals to use the services of a particular Healthcare Provider. You
					ought to get any extra information important to settle on an informed decision preceding using a particular Healthcare Provider.&nbsp; YOU
					SHOULD CONSULT A PHYSICIAN BEFORE MAKING ANY MEDICAL DECISIONS BASED ON THE INFORMATION YOU RECEIVE FROM THIS WEBSITE AND THE WEBSITE
					MATERIALS. You accept all responsibility regarding picking any Healthcare Provider, and all other medical decisions you make, regardless of
					whether you got information about such Healthcare Provider on or through this Website, and you acknowledge and agree that Website. Radius
					Care Inc. and its licensors, providers and associates, and the directors, officers, specialists, and agents of each, accept no (and disclaim
					all) responsibility or liability of any sort, for any medical decision you make, any exhortation, treatment or other services rendered by
					any Healthcare Provider, or for any malpractice cases and different cases that may emerge directly or in an indirect way from any such
					counsel, treatment or other services.
				</Typography>

				<Typography>
					This Website and all the materials contained on it are our property and/or the property of our affiliates or licensors, and are protected by
					copyright, trademark, and other intellectual property laws. We provide the Website for personal noncommercial use only. You may only use
					this Website and the materials on it as authorized by us. You may not use this Website or the materials on it in any manner that violates
					the privacy rights, publicity rights, copyrights, trademark rights, patent rights, contract rights, or any other rights belonging to us or a
					third party. We reserve the right, at any time and without notice, to suspend, cancel, or terminate your right to use the Website (or any
					portion of the Website) for violation (whether repeated or not) of copyrights or any other rights belonging to us or a third party. Unless
					authorized in these Terms and Conditions of Service or by the owner of the materials, you may not modify, copy, reproduce, republish,
					upload, post, transmit, translate, sell, create derivative works, exploit, or distribute in any manner or medium (including by e-mail or
					other electronic means) any material from the Website.&nbsp; So long as you abide by these of Terms and Conditions of Service, we grant you
					a limited, revocable, non-transferable, non-assignable, non-sublicensable license to use the Website Materials and print copies of the
					Website Materials for your own personal use. By using the Website and/or the Website Materials provided on or through the Website, you
					consent to receiving electronic communications from Radius Care Inc. These electronic communications may include notices about applicable
					fees and charges, transactional information and other information concerning or related to the Website and/or Website Materials provided on
					or through the Website.&nbsp; These electronic communications are part of your relationship with Radius Care Inc.&nbsp; You agree that any
					notices, agreements, disclosures or other communications that we send you electronically will satisfy any legal communication requirements,
					including that such communications be in writing.
				</Typography>

				<Typography>
					<b>Password Maintenance and Responsibility.</b>
				</Typography>
				<p></p>
				<Typography>
					In order to access the Website Materials&nbsp; you must complete the registration process by providing us with current, complete and
					accurate information as prompted by the registration form. All information you submit through the Website will be treated in accordance with
					our Privacy Policy.&nbsp;&nbsp; When signing up for an account, you will choose a password and a user name.&nbsp; You are entirely
					responsible for maintaining the confidentiality of your password and account. Furthermore, you are entirely responsible for any and all
					activities that occur under your account. You agree to notify Radius Care Inc. immediately of any unauthorized use of&nbsp; your account or
					any other breach of security by e-mailing us at support@radiuscareinc.com. Radius Care Inc. will not be liable for any loss that you may
					incur as a result of someone else using your password or account, either with or without&nbsp; your knowledge. However, you are liable for
					losses incurred by Radius Care Inc. or another party due to someone else using&nbsp; your account or password. You may not use anyone else’s
					account at any time, without the permission of the account holder.
				</Typography>

				<Typography>
					<b>Disclaimers</b>
				</Typography>

				<Typography>
					THE WEBSITE MATERIALS, INFORMATION, AND SERVICES OFFERED ON OR THROUGH THE WEBSITE ARE PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND
					EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
					INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT THAT THE
					WEBSITE OR ANY OF ITS FUNCTIONS WILL BE UNINTERRUPTED OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT ANY PART OF THIS WEBSITE,
					INCLUDING MESSAGE BOARDS, OR THE SERVERS THAT MAKE THE WEBSITE AVAILABLE, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE EXPRESSLY
					DISCLAIM LIABILITY FOR ANY DAMAGES OR INJURY CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN
					OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE
					OF RECORD, WHETHER FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION. EACH USER SPECIFICALLY
					ACKNOWLEDGES THAT WE ARE NOT LIABLE FOR THE DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER THIRD PARTIES, SUBSCRIBERS, MEMBERS OR OTHER
					USERS OF THE MESSAGE BOARDS AND THAT THE RISK OF INJURY FROM THE FOREGOING RESTS ENTIRELY WITH EACH USER. WE DO NOT MAKE ANY REPRESENTATIONS
					OR WARRANTIES ABOUT THE CORRECTNESS, ACCURACY, TIMELINESS, OR RELIABILITY OF THIS WEBSITE OR THIRD-PARTY SITES. USE OF ANY INFORMATION ON
					THE WEBSITE OR THIRD-PARTY SITES IS AT THE USER’S OWN RISK. UNDER NO CIRCUMSTANCES WILL WE BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY
					RELIANCE ON INFORMATION OBTAINED THROUGH THE WEBSITE. You must provide and are solely responsible for all hardware and/or software necessary
					to access the Website. You assume the entire cost of and responsibility for any damage to, and all necessary maintenance, repair or
					correction of, that hardware and/or software. Any financial information provided on the Website is for informational purposes only, and is
					not intended for trading or investing purposes, or for commercial use. The Website should not be used in any high-risk activities where
					damage or injury to persons, property, environment, finances, or business may result if an error occurs. You assume any and all risk for
					your use of financial information provided on the Website.
				</Typography>

				<Typography>
					<b>Limitation of Liability</b>
				</Typography>
				<p></p>
				<Typography>
					UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL WE, OUR SUBSIDIARY COMPANIES, OR AFFILIATES BE LIABLE FOR ANY
					DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE WEBSITE, INCLUDING
					ITS MATERIALS, PRODUCTS, OR SERVICES, OR THIRD-PARTY MATERIALS, PRODUCTS, OR SERVICES MADE AVAILABLE THROUGH THE WEBSITE, EVEN IF WE ARE
					ADVISED BEFOREHAND OF THE POSSIBILITY OF SUCH DAMAGES. (BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN CATEGORIES
					OF DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN SUCH STATES, OUR LIABILITY AND THE LIABILITY OF OUR SUBSIDIARY COMPANIES OR
					AFFILIATES IS LIMITED TO THE GREATEST EXTENT THAT IT CAN BE LIMITED UNDER SUCH STATE LAW.) IN NO EVENT WILL WE OR ANY PERSON OR ENTITY
					INVOLVED IN CREATING, PRODUCING OR DISTRIBUTING THE WEBSITE OR RELATED ONLINE SERVICES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
					SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE OF OR INABILITY TO USE THE MESSAGE BOARDS OR OTHER INTERACTIVE FEATURES, OR OUT OF
					THE BREACH OF ANY WARRANTY. IF YOU ARE DISSATISFIED WITH THE WEBSITE, ANY MATERIALS, PRODUCTS, OR SERVICES ON THE WEBSITE, OR WITH ANY OF
					THE WEBSITE’S TERMS AND CONDITIONS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE WEBSITE.
				</Typography>

				<Typography>
					<b>Ownership</b>
				</Typography>

				<Typography>
					All content provided or displayed by Radius Care Inc. through the Website, including (without limitation) all designs, infrastructure,
					graphics, pictures, illustrations, software, artwork, video, music, sound, names, words, titles, phrases, logos and marks are considered
					part of the defined term “Website.”&nbsp; The Website and Website Materials are owned or licensed by Radius Care Inc. and are protected by
					copyright, trademark and other intellectual property laws. Radius Care Inc. expressly reserves all rights in the Website, the Website&nbsp;
					Materials, that are not specifically granted to you. You acknowledge that all right, title and interest in the Website, the Website
					Materials, and any update, adaptation, translation, customization or derivative work thereof, and all intellectual property rights therein
					will remain with Radius Care Inc. (or third party suppliers, if applicable), and that the Website and Website Materials are licensed and not
					“sold” to you.
				</Typography>

				<Typography>
					<b>Third-Party Websites</b>
				</Typography>

				<Typography>
					The Website may provide links to Web sites maintained by third parties (“Third-Party Websites”). Any information, products, software, or
					services provided on or through Third-Party Websites are controlled by the operators of such sites and not by us or our subsidiary
					companies. When you access these Third-Party Websites, you do so at your own risk. You acknowledge and agree that the Third-Party Websites
					may have different privacy policies and Terms and Conditions and/or user guides and business practices than RadiusCare Inc., and you further
					acknowledge and agree that your use of such Third-Party Websites is governed by the respective Third-Party Website privacy policy and Terms
					and Conditions and/or user guides.&nbsp; You hereby agree to comply with any and all Terms and Conditions, users guides and privacy policies
					of any of Third-Party Websites.&nbsp; RadiusCare, Inc. is providing links to the Third-Party Websites to you as a convenience, and
					RadiusCare, Inc. does not verify, make any representations or take responsibility for such Third-Party Websites, including, without
					limitation, the truthfulness, accuracy, quality or completeness of the content, services, links displayed and/or any other activities
					conducted on or through such Third-Party Websites. YOU AGREE THAT RadiusCare, Inc. WILL NOT, UNDER ANY CIRCUMSTANCES, BE RESPONSIBLE OR
					LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY GOODS, SERVICES, INFORMATION, RESOURCES AND/OR CONTENT AVAILABLE ON OR THROUGH ANY THIRD-PARTY SITES
					AND/OR THIRD-PARTY DEALINGS OR COMMUNICATIONS, OR FOR ANY HARM RELATED THERETO, OR FOR ANY DAMAGES OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY
					OR IN CONNECTION WITH YOUR USE OR RELIANCE ON THE CONTENT OR BUSINESS PRACTICES OF ANY THIRD-PARTY.&nbsp; Any reference on the Website to
					any product, service, publication, institution, organization of any third-party entity or individual does not constitute or imply RadiusCare
					Inc.’s endorsement or recommendation.
				</Typography>
				<Typography>
					<b>Termination</b>
				</Typography>

				<Typography>
					You may terminate the Terms and Conditions at any time by closing your account, discontinuing your use of this Website and providing us with
					a notice of termination. We reserve the right, without notice and in our sole discretion, to terminate your right to use this Website, or
					any portion of this Website, and to block or prevent your future access to and use of this Website or any portion of this Website.&nbsp; We
					may notify you of such termination or suspension but are not required to do so.
				</Typography>

				<Typography>
					<b>Dispute Resolution and Arbitration; Class Action Waiver</b>
				</Typography>

				<Typography>
					Please read the following (this “Provision”) carefully.&nbsp; It affects your rights. Most customer concerns can be resolved quickly and to
					a customer’s satisfaction by contacting us at support@raiduscareinc.com. This Provision facilitates the prompt and efficient resolution of
					any disputes that may arise between you and Radius Care Inc.&nbsp; Arbitration is a form of private dispute resolution in which persons with
					a dispute waive their rights to file a lawsuit, to proceed in court and to a jury trial, and instead submit their disputes to a neutral
					third person (or arbitrator) for a binding decision.&nbsp; You have the right to opt-out of this Provision (as explained below), which means
					you would retain your right to litigate your disputes in a court, either before a judge or jury. Please read this Provision carefully.&nbsp;
					It provides that all Disputes between you and RadiusCare, Inc. shall be resolved by binding arbitration.&nbsp; Arbitration replaces the
					right to go to court.&nbsp; In the absence of this arbitration agreement, you may otherwise have a right or opportunity to bring claims in a
					court, before a judge or jury, and/or to participate in or be represented in a case filed in court by others (including, but not limited to,
					class actions).&nbsp; Except as otherwise provided, entering into this agreement constitutes a waiver of your right to litigate claims and
					all opportunity to be heard by a judge or jury.&nbsp; There is no judge or jury in arbitration, and court review of an arbitration award is
					limited.&nbsp; The arbitrator must follow this agreement and can award the same damages and relief as a court (including attorney’s fees).
					For the purpose of this Provision, “RadiusCare Inc.” means RadiusCare, Inc. and its parents, subsidiary, and affiliate companies, and each
					of their respective officers, directors, employees, and agents.&nbsp; The term “Dispute” means any dispute, claim, or controversy between
					you and RadiusCare, Inc. regarding any aspect of your relationship with RadiusCare Inc., whether based in contract, statute, regulation,
					ordinance, tort (including, but not limited to, fraud, misrepresentation, fraudulent inducement, or negligence), or any other legal or
					equitable theory, and includes the validity, enforceability or scope of this Provision (with the exception of the enforceability of the
					Class Action Waiver clause below).&nbsp; “Dispute” is to be given the broadest possible meaning that will be enforced, and shall include any
					claims against other parties relating to services or products provided or billed to you (such as RadiusCare Inc.’s licensors, suppliers,
					dealers or third-party vendors) whenever you also assert claims against us in the same proceeding.
				</Typography>

				<Typography>
					WE EACH AGREE THAT, EXCEPT AS PROVIDED BELOW, ANY AND ALL DISPUTES, AS DEFINED ABOVE, WHETHER PRESENTLY IN EXISTENCE OR BASED ON ACTS OR
					OMISSIONS IN THE PAST OR IN THE FUTURE, WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION RATHER THAN IN COURT IN ACCORDANCE
					WITH THIS PROVISION.
				</Typography>

				<Typography>
					<b>Pre-Arbitration Claim Resolution</b>
				</Typography>

				<Typography>
					For all Disputes, whether pursued in court or arbitration, you must first give RadiusCare, Inc. an opportunity to resolve the Dispute.&nbsp;
					You must commence this process by mailing written notification to RadiusCare Inc., [Answer #10].&nbsp; That written notification must
					include (1) your name, (2) your address, (3) a written description of your claim, and (4) a description of the specific relief you
					seek.&nbsp; If RadiusCare, Inc. does not resolve the Dispute within 45 days after it receives your written notification, you may pursue your
					Dispute in arbitration.&nbsp; You may pursue your Dispute in a court only under the circumstances described below.
				</Typography>

				<Typography>
					<b>Exclusions from Arbitration/Right to Opt-Out</b>
				</Typography>

				<Typography>
					Notwithstanding the above, you or RadiusCare, Inc. may choose to pursue a Dispute in court and not by arbitration if (a) the Dispute
					qualifies, it may be initiated in small claims court; or (b) YOU OPT-OUT OF THESE ARBITRATION PROCEDURES WITHIN 30 DAYS FROM THE DATE THAT
					YOU FIRST CONSENT TO THIS AGREEMENT (the “Opt-Out Deadline”).&nbsp; You may opt out of this Provision by mailing written notification to
					RadiusCare Inc., [Answer #10].&nbsp; Your written notification must include (1) your name, (2) your address, and (3) a clear statement that
					you do not wish to resolve disputes with RadiusCare, Inc. through arbitration.&nbsp; Your decision to opt-out of this Provision will have no
					adverse effect on your relationship with RadiusCare Inc.&nbsp; Any opt-out request received after the Opt-Out Deadline will not be valid and
					you must pursue your Dispute in arbitration or small claims court.
				</Typography>

				<Typography>
					<b>Arbitration Procedures</b>
				</Typography>

				<Typography>
					If this Provision applies and the Dispute is not resolved as provided above (Pre-Arbitration Claim Resolution) either you or RadiusCare,
					Inc. may initiate arbitration proceedings.&nbsp; The American Arbitration Association (“AAA”), www.adr.org, or JAMS, www.jamsadr.com, will
					arbitrate all Disputes, and the arbitration will be conducted before a single arbitrator.&nbsp; The arbitration shall be commenced as an
					individual arbitration, and shall in no event be commenced as a class arbitration.&nbsp; All issues shall be for the arbitrator to decide,
					including the scope of this Provision.
				</Typography>

				<Typography>
					For arbitration before AAA, for Disputes of less than $75,000, the AAA’s Supplementary Procedures for Consumer-Related Disputes will apply;
					for Disputes involving $75,000 or more, the AAA’s Commercial Arbitration Rules will apply.&nbsp; In either instance, the AAA’s Optional
					Rules For Emergency Measures Of Protection shall apply.&nbsp; The AAA rules are available at www.adr.org or by calling 1-800-778-7879.&nbsp;
					For arbitration before JAMS, the JAMS Comprehensive Arbitration Rules &amp; Procedures and the JAMS Recommended Arbitration Discovery
					Protocols For Domestic, Commercial Cases will apply.&nbsp; The JAMS rules are available at www.jamsadr.com or by calling
					1-800-352-5267.&nbsp; This Provision governs in the event it conflicts with the applicable arbitration rules.&nbsp; Under no circumstances
					will class action procedures or rules apply to the arbitration. Because the Website and these Terms and Conditions concern interstate
					commerce, the Federal Arbitration Act (“FAA”) governs the arbitrability of all Disputes.&nbsp; However, the arbitrator will apply applicable
					substantive law consistent with the FAA and the applicable statute of limitations or condition precedent to suit. Arbitration Award – The
					arbitrator may award on an individual basis any relief that would be available pursuant to applicable law, and will not have the power to
					award relief to, against or for the benefit of any person who is not a party to the proceeding.&nbsp; The arbitrator will make any award in
					writing but need not provide a statement of reasons unless requested by a party.&nbsp; Such award will be final and binding on the parties,
					except for any right of appeal provided by the FAA, and may be entered in any court having jurisdiction over the parties for purposes of
					enforcement.
				</Typography>

				<Typography>
					Location of Arbitration – You or RadiusCare, Inc. may initiate arbitration in either New York City, New York or the federal judicial
					district that includes your billing address.&nbsp; In the event that you select the federal judicial district that includes your billing
					address, RadiusCare, Inc. may transfer the arbitration to New York City, New York in the event that it agrees to pay any additional fees or
					costs you incur as a result of the transfer, as determined by the arbitrator.
				</Typography>

				<Typography>
					Payment of Arbitration Fees and Costs – RadiusCare, Inc. will pay all arbitration filing fees and arbitrator’s costs and expenses upon your
					written request given prior to the commencement of the arbitration.&nbsp; You are responsible for all additional fees and costs that you
					incur in the arbitration, including, but not limited to, attorneys or expert witnesses.&nbsp; Fees and costs may be awarded as provided
					pursuant to applicable law.&nbsp; In addition to any rights to recover fees and costs under applicable law, if you provide notice and
					negotiate in good faith with RadiusCare, Inc. as provided in the section above titled “Pre-Arbitration Claim Resolution” and the arbitrator
					concludes that you are the prevailing party in the arbitration, you will be entitled to recover reasonable attorney’s fees and costs as
					determined by the arbitrator.
				</Typography>

				<Typography>
					<b>Class Action Waiver</b>
				</Typography>

				<Typography>
					Except as otherwise provided in this Provision, the arbitrator may not consolidate more than one person’s claims, and may not otherwise
					preside over any form of a class or representative proceeding or claims (such as a class action, consolidated action or private attorney
					general action) unless both you and RadiusCare, Inc. specifically agree to do so following initiation of the arbitration.&nbsp; If you
					choose to pursue your Dispute in court by opting out of the Provision, as specified above, this Class Action Waiver will not apply to
					you.&nbsp; Neither you, nor any other user of the Website can be a class representative, class member, or otherwise participate in a class,
					consolidated, or representative proceeding without having complied with the opt-out requirements above.
				</Typography>

				<Typography>
					<b>Jury Waiver</b>
				</Typography>

				<Typography>
					You understand and agree that by entering into this Agreement you and RadiusCare, Inc. are each waiving the right to a jury trial or a trial
					before a judge in a public court.&nbsp; In the absence of this Provision, you and RadiusCare, Inc. might otherwise have had a right or
					opportunity to bring Disputes in a court, before a judge or jury, and/or to participate or be represented in a case filed in court by others
					(including class actions).&nbsp; Except as otherwise provided below, those rights are waived.&nbsp; Other rights that you would have if you
					went to court, such as the right to appeal and to certain types of discovery, may be more limited or may also be waived.
				</Typography>

				<Typography>
					<b>Severability</b>
				</Typography>

				<Typography>
					If any clause within this Provision (other than the Class Action Waiver clause above) is found to be illegal or unenforceable, that clause
					will be severed from this Provision, and the remainder of this Provision will be given full force and effect.&nbsp; If the Class Action
					Waiver clause is found to be illegal or unenforceable, this entire Provision will be unenforceable and the Dispute will be decided by a
					court.
				</Typography>

				<Typography>
					<b>Continuation</b>
				</Typography>

				<Typography>
					This Provision shall survive the termination of your service with RadiusCare, Inc. or its affiliates.&nbsp; Notwithstanding any provision in
					this Agreement to the contrary, we agree that if RadiusCare, Inc. makes any change to this Provision (other than a change to the Notice
					Address), you may reject any such change and require RadiusCare, Inc. to adhere to the language in this Provision if a dispute between us
					arises.
				</Typography>

				<Typography>
					<b>General</b>
				</Typography>

				<Typography>
					RadiusCare, Inc. prefers to advise you if we feel you are not complying with these Terms and Conditions and to recommend any necessary
					corrective action.&nbsp; However, certain violations of these Terms and Conditions, as determined by RadiusCare Inc., may result in
					immediate termination of your access to this Website without prior notice to you.&nbsp; The Federal Arbitration Act, New York state law and
					applicable U.S. federal law, without regard to the choice or conflicts of law provisions, will govern these Terms and Conditions.&nbsp;
					Foreign laws do not apply.&nbsp; The United Nations on Contracts for the International Sale of Goods and any laws based on the Uniform
					Computer Information Transactions Act (UCITA) shall not apply to this Agreement.&nbsp; Except for Disputes subject to arbitration as
					described above, any disputes relating to these Terms and Conditions or this Website will be heard in the courts located in New York, New
					York.&nbsp; If any of these Terms and Conditions is found to be inconsistent with applicable law, then such term shall be interpreted to
					reflect the intentions of the parties, and no other terms will be modified.&nbsp; RadiusCare Inc.’s failure to enforce any of these Terms
					and Conditions is not a waiver of such term.&nbsp; These Terms and Conditions are the entire agreement between you and RadiusCare, Inc. and
					supersede all prior or contemporaneous negotiations, discussions or agreements between you and RadiusCare, Inc. about this Website.&nbsp;
					The proprietary rights, disclaimer of warranties, representations made by you, indemnities, limitations of liability and general provisions
					shall survive any termination of these Terms and Conditions.
				</Typography>

				<Typography>
					<b>Contact Us</b>
				</Typography>
				<Typography>
					If you have any questions, comments, or complaints about our Terms and Conditions, please contact us at{" "}
					<a href="mailto:support@radiuscare.com">support@radiuscare.com.</a>
				</Typography>
			</Container>
		</PageContainer>
	);
};

export default TermsOfService;
