import { Box, Button, Stack, Typography } from "@mui/material";
import Icon1 from "../../assets/icons/open-book.svg";
import Icon2 from "../../assets/icons/next-blue.svg";
interface Props {
  title: any;
  path: any;
  target?: string;
}

const IconLink = ({ title, path, target }: Props) => {
  return (
    <Stack
      flexDirection={"row"}
      alignItems="center"
      justifyContent={"center"}
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "1rem",
        boxShadow: 4,
        minHeight: "5rem",
        p: 3,
      }}
    >
      <Box
        sx={{ height: "3rem" }}
        component="img"
        src={Icon1}
        alt={"Open Book Icon"}
      />

      <Typography variant="h6" sx={{ flex: "1", px: 2, fontWeight: 400 }}>
        {title}
      </Typography>
      <Button target={target} href={path}>
        <Box
          sx={{ height: "2rem" }}
          component="img"
          src={Icon2}
          alt={"Link Icon"}
        />
      </Button>
    </Stack>
  );
};

export default IconLink;
