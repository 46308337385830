import { Helmet } from "react-helmet";
import PageContainer from "shared/components/PageContainer";
import AboutBanner from "./components/AboutBanner";
import AboutMission from "./components/AboutMission";
import AboutStats from "./components/AboutStats";
import AboutValues from "./components/AboutValues";

const About = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>Radius Care | About Us</title>
      </Helmet>
      <AboutBanner />
      <AboutStats />
      <AboutMission />
      <AboutValues />
    </PageContainer>
  );
};

export default About;
