import {
  TopBanner,
  FeaturesAndBenefits,
  MobileAppScreens,
  AiBanner,
  AppStats,
} from "./components/index";
import { Helmet } from "react-helmet";
import PageContainer from "shared/components/PageContainer";

const Home = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>Radius Care | Home</title>
      </Helmet>
      <TopBanner />
      <AppStats />
      <MobileAppScreens />
      <FeaturesAndBenefits />
      <AiBanner />
    </PageContainer>
  );
};

export default Home;
