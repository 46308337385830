import { Box, List, ListItem, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Helmet } from "react-helmet";
import GradientHorizontal from "shared/components/GradientHorizontal";
import PageContainer from "shared/components/PageContainer";

const AnnAetna = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>Radius Care | Announcement - Welcome UnitedHealthcare</title>
      </Helmet>
      <Container maxWidth="sm">
        <Box sx={{ pb: 4 }}>
          <Typography variant="h1" sx={{ pb: 2 }}>
            Aetna brings better healthcare solutions to their customers with the
            Radius Care Connection
          </Typography>
          <GradientHorizontal
            width={"4rem"}
            height={".25rem"}
          ></GradientHorizontal>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 700 }}>By Derek Moore</Typography>
          <Typography>
            Radius Care has partnered with Aetna to give their 26 million
            customers instant access to their tech-driven healthcare solutions.
          </Typography>
          <Typography>
            Radius Care users insured with Aetna can now connect their insurance
            in the app to get personalized healthcare recommendations.
          </Typography>
          <Typography>
            Aetna already offers health guides including “Living healthy”,
            “Understanding healthcare”, and “Managing health” to their members.
            The new connection to Radius Care allows their members to get the
            same information inside the Radius Care app built around their
            specific health needs and conditions.
          </Typography>
          <Typography>
            “Equipping users with accurate healthcare knowledge is so important.
            Getting better starts with knowledge.” Says Justin Huddy, CEO and
            founder of Radius Care.
          </Typography>
          <Typography>
            <List sx={{ pl: 6, listStyleType: "decimal" }}>
              <ListItem sx={{ display: "list-item" }}>
                Set up a Radius Care Profile
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Connect to your Aetna account
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Let the app automatically sync medical records and provide
                personalized healthcare recommendations
              </ListItem>
            </List>
          </Typography>
          <Typography>
            Discover more about Aetna health insurance and see their member
            guides here. Or sign up free to Radius Care today.
          </Typography>
        </Box>
      </Container>
    </PageContainer>
  );
};

export default AnnAetna;
