import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React from "react";
import AccordianContainer from "shared/components/AccordianContainer";
import GradientHorizontal from "shared/components/GradientHorizontal";
import ExpandMoreIcon from "../../../assets/icons/next-blue.svg";

const FAQsSafety = () => {
  const [expanded, setExpanded] = React.useState<string | false>();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <AccordianContainer>
      <Box sx={{ pb: 4 }}>
        <Typography variant="h4" sx={{ pb: 2 }}>
          Safety & Standards
        </Typography>
        <GradientHorizontal
          width={"4rem"}
          height={".25rem"}
        ></GradientHorizontal>
      </Box>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            Is my data safe with Radius Care?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, your security and privacy are important to us. We are fully
            HIPAA compliant, so your health data is totally protected, 100%
            private, and secure. We’re also an authorized technology partner of
            CMS (Center of Medicare and Medicaid Services).
          </Typography>
          <Typography>
            To keep your data safe, our level of encryption is the same as the
            encryption used by banks and other financial institutions.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">What is HIPAA Compliance?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            HIPAA stands for Health Insurance Portability and Accountability
            Act. Being fully compliant means following the guidelines set out by
            HIPAA to ensure we can guarantee your health data is safe and secure
            in our system.
          </Typography>
          <Typography>Radius Care is fully HIPAA compliant.</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            Is it secure for me to link up my insurance with Radius Care?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, it is. We maintain a very secure connection between your
            insurer's databases and our systems. We will never alter or amend
            your insurance. The connection is needed so that our AI can read
            your health records and create personalized health recommendations
            unique to you and your health needs.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            How do I connect my insurance with Radius Care?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To connect your insurance with Radius Care you first need to create
            your profile. Hit Sign up at the top of this page and fill in the
            basic profile details.
          </Typography>
          <Typography>
            After you’ve set up your profile you’ll be prompted to connect with
            your insurer's databases via username and passcode. To do this, the
            only information you need is:
          </Typography>
          <Typography>
            <List sx={{ listStyleType: "disk", pl: 6 }}>
              <ListItem sx={{ display: "list-item" }}>
                Your insurer's company name
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Your insurance username and passcode
              </ListItem>
            </List>
          </Typography>
          <Typography>
            <Link href="/">
              Click here to set up your account and connect your insurance
            </Link>
          </Typography>
          <Typography>
            <List sx={{ listStyleType: "decimal", pl: 6 }}>
              <ListItem sx={{ display: "list-item" }}>
                Create your profile - Set up your secure profile in seconds by
                entering your email address and create a password for Radius
                Care.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Connect your insurance - When prompted, enter your user name and
                password for your medical insurance and/or Medicare.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Improve your healthcare - Access better health choices, prices
                and treatments
              </ListItem>
            </List>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">
            Where is my personal health data stored?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The health data you provide, and the data collected from your
            insurer's databases are stored in our secure cloud-based system. Our
            cloud system is hosted Amazon Web Services.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={
            <Box
              sx={{ height: "2rem" }}
              component="img"
              src={ExpandMoreIcon}
              alt={"Expand More Icon"}
            />
          }
        >
          <Typography variant="h5">How do you protect my privacy?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            As well as being HIPAA compliant, we also have a rigorous privacy
            policy that we follow to ensure your privacy is protected. You can
            read our full privacy policy here
          </Typography>
        </AccordionDetails>
      </Accordion>
    </AccordianContainer>
  );
};

export default FAQsSafety;
