import { Box, Container, Stack, Typography } from "@mui/material";
import GradientHorizontal from "shared/components/GradientHorizontal";

const HowItWorksVideoBanner = () => {
	return (
		<Stack
			alignItems="center"
			justifyContent="center"
			sx={{
				width: "100%",
				py: 8,
				background: "linear-gradient(90deg, rgba(228,229,245,1) 0%, rgba(250,247,240,1) 100%)",
			}}
		>
			<Container maxWidth="md">
				<Stack flexDirection="row" flexWrap={"wrap"}>
					<Stack
						justifyContent={"center"}
						sx={{
							width: { xs: "100%", md: "40%" },
							order: { xs: "1", sm: "0" },
						}}
					>
						<Box sx={{pr: { xs: 0, md: 4 },}}>
							<Typography variant="h2" sx={{ pb: 2 }}>
								How Does Radius Care Work?
							</Typography>
							<GradientHorizontal width={"4rem"} height={".25rem"} />
							<Typography component="p" sx={{ mt: 2 }}>
								Radius Care transforms your healthcare journey by simplifying every step. It helps you reach your healthcare goals faster with
								no stress. Just get set up. Radius Care handles the rest.
							</Typography>
						</Box>
					</Stack>
					<Stack
						justifyContent={"center"}
						alignItems={"center"}
						sx={{
							width: { xs: "100%", md: "60%" },
							order: { xs: "0", sm: "1" },
							pb: { xs: 8, sm: 0 },
						}}
					>
						<Box
							sx={{ width: "100%", borderRadius: "2rem" }}
							component="img"
							src={"./media/images/banners/radius-care-journey.jpg"}
							alt="Radius Care Journey"
						/>
					</Stack>
				</Stack>
			</Container>
		</Stack>
	);
};

export default HowItWorksVideoBanner;
