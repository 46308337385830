import { Box } from "@mui/material";

interface Props {
  width: any;
  height: any;
}

const GradientVertical = ({ width, height }: Props) => {
  return (
    <Box
      width={width}
      height={height}
      sx={{
        background:
          "linear-gradient(180deg, rgba(0,10,109,1) 0%, rgba(224,159,13,1) 100%)",
      }}
    ></Box>
  );
};

export default GradientVertical;
