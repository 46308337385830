import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
  Container,
} from "@mui/material";
import { Helmet } from "react-helmet";
import FAQsTopBanner from "./components/FAQsTopBanner";
import PageContainer from "shared/components/PageContainer";
import FAQsGettingStarted from "./components/FAQsGettingStarted";
import GradientHorizontal from "shared/components/GradientHorizontal";
import FAQsUsingRadius from "./components/FAQsUsingRadius";
import FAQsSafety from "./components/FAQsSafety";
import FAQsYourAccount from "./components/FAQsYourAccount";
import FAQsFixProblem from "./components/FAQsFixProblem";
import FAQsUserGuide from "./components/FAQsUserGuide";

import { Link, Element } from "react-scroll";

const FAQs = () => {
  return (
    <Box
      sx={{
        "& .scroll-padding-top": { paddingTop: "4rem" },
        "& .anchor-link": { cursor: "pointer" },
      }}
    >
      <PageContainer>
        <Helmet>
          <title>Radius Care | FAQs</title>
        </Helmet>
        <FAQsTopBanner></FAQsTopBanner>
      </PageContainer>
      <Box
        sx={{
          width: "100%",
          mt: 12,
          py: 8,
          background:
            "linear-gradient(90deg, rgba(228,229,245,1) 0%, rgba(250,247,240,1) 100%)",
        }}
      >
        <Container maxWidth={"md"}>
          <Stack alignItems={"center"} sx={{ textAlign: "center", pb: 8 }}>
            <Typography sx={{ color: "gray.main" }}>
              Radius Care - FAQs
            </Typography>
            <Typography variant="h2" sx={{ mb: 2 }}>
              Frequently Asked Questions
            </Typography>
            <GradientHorizontal width={"8rem"} height={".25rem"} />
          </Stack>
          <Card sx={{ boxShadow: 4 }}>
            <CardContent>
              <Stack
                sx={{
                  justifyContent: "space-between",
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  "& > a": {
                    textAlign: "center",
                    textDecoration: "none",
                    color: "gray.main",
                    ":hover": { color: "primary.main" },
                  },
                  p: 2,
                }}
              >
                <Link
                  to="GettingStarted"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="anchor-link"
                >
                  <Typography component={"span"}>Getting Started</Typography>
                </Link>
                <Link
                  to="UsingRadius"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="anchor-link"
                >
                  <Typography component={"span"}>Using Radius Care</Typography>
                </Link>
                <Link
                  to="Safety"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="anchor-link"
                >
                  <Typography component={"span"}>Safety & Standards</Typography>
                </Link>
                <Link
                  to="YourAccount"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="anchor-link"
                >
                  <Typography component={"span"}>Your Account</Typography>
                </Link>
                <Link
                  to="FixProblem"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="anchor-link"
                >
                  <Typography component={"span"}>Fix a Problem</Typography>
                </Link>
                <Link
                  to="UserGuide"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="anchor-link"
                >
                  <Typography component={"span"}>User Guide</Typography>
                </Link>
              </Stack>
            </CardContent>
          </Card>
        </Container>
        <Element name="GettingStarted" className="scroll-padding-top">
          <FAQsGettingStarted></FAQsGettingStarted>
        </Element>
        <Element name="UsingRadius" className="scroll-padding-top">
          <FAQsUsingRadius></FAQsUsingRadius>
        </Element>
        <Element name="Safety" className="scroll-padding-top">
          <FAQsSafety></FAQsSafety>
        </Element>
        <Element name="YourAccount" className="scroll-padding-top">
          <FAQsYourAccount></FAQsYourAccount>
        </Element>
        <Element name="FixProblem" className="scroll-padding-top">
          <FAQsFixProblem></FAQsFixProblem>
        </Element>
        <Element name="UserGuide" className="scroll-padding-top">
          <FAQsUserGuide></FAQsUserGuide>
        </Element>
      </Box>
    </Box>
  );
};

export default FAQs;
