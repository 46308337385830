import { Drawer } from "@mui/material";
import NavItems from "./NavItems";

interface Props {
  sideBar: boolean;
}

const SideBar = ({ sideBar }: Props) => {
  return (
    <Drawer
      variant="persistent"
      open={window.innerWidth <= 10000 ? !sideBar : sideBar}
      anchor="top"
      elevation={4}
      sx={{
        zIndex: 3,
        height: "100vh",
        alignItems: "center",
        "& .MuiDrawer-paper": {
          boxShadow: 4,
          width: "100%",
          height: "100vh",
          pt: 12,
        },
        "& .nav-item": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "5rem",
          borderBottom: "1px solid",
          borderColor: "gray.light",
          boxSizing: "border-box",
          color: "primary.main",
          p: 1,
          transition: "all .25s ease",
        },
        "& .nav-item:hover": {
          borderColor: "primary.main",
          color: "primary.main",
        },
      }}
    >
      <NavItems></NavItems>
    </Drawer>
  );
};

export default SideBar;
