import { Button } from "@mui/material";
interface Props {
	variant: any;
	size: any;
}

const ConnectButton = ({ variant, size }: Props) => {
	let consumerUrl = "";
	const environment: any = ((globalThis as any)?.["__RUNTIME_CONFIG"] as any)?.ENVIRONMENT_NAME;
	switch (environment) {
		case "Development":
			consumerUrl = "https://consumer.dev.radiuscare.link";
			break;
		case "Staging":
			consumerUrl = "https://consumer.stage.radiuscare.link";
			break;
		case "Production":
			consumerUrl = "https://consumer.radiuscare.com";
			break;
		default:
			consumerUrl = "https://consumer.radiuscare.com";
			break;
	}
	return (
		<Button variant={variant} size={size} href={consumerUrl + "/signup"} target={"_blank"} sx={{ textTransform: "none" }}>
			Join Now - It's Free!
		</Button>
	);
};

export default ConnectButton;
