import { Box, Stack } from "@mui/material";
import { NavBar, Footer, SideBar } from "./components/index";
import { useState } from "react";

type Props = React.PropsWithChildren<{
  setThemeMode: any;
  themeMode: any;
}>;

function Shell({ children, setThemeMode, themeMode }: Props) {
  const [searchBar, setSearchBar] = useState(true);
  const [sideBar, setSideBar] = useState(true);

  return (
    <Box>
      <NavBar
        setSideBar={setSideBar}
        sideBar={sideBar}
        setThemeMode={setThemeMode}
        themeMode={themeMode}
        setSearchBar={setSearchBar}
        searchBar={searchBar}
      />
      <Stack flexDirection={"row"}>
        <SideBar sideBar={sideBar} />
        <Box width={"100%"}>
          {children}
          <Footer />
        </Box>
      </Stack>
    </Box>
  );
}

export default Shell;
