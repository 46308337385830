import { Box } from "@mui/material";
import { Helmet } from "react-helmet";
import ResourcesTopBanner from "./components/ResourcesTopBanner";
import ResourcesList from "./components/ResourcesList";
import PageContainer from "shared/components/PageContainer";

const Resources = () => {
  return (
    <Box>
      <PageContainer>
        <Helmet>
          <title>Radius Care | News Room</title>
        </Helmet>
        <ResourcesTopBanner></ResourcesTopBanner>
      </PageContainer>
      <Box sx={{ pt: 12 }}>
        <ResourcesList></ResourcesList>
      </Box>
    </Box>
  );
};

export default Resources;
