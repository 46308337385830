import { Box, Container, Stack, Typography } from "@mui/material";
import AppStoreBtn from "shared/components/AppStoreBtns";
import ConnectButton from "shared/components/ConnectButton";
import GradientHorizontal from "shared/components/GradientHorizontal";

const TopBanner = () => {
	return (
		<Container maxWidth="md">
			<Stack flexDirection={"row"} alignItems={"center"} flexWrap={"wrap"} justifyContent={"center"} sx={{ minHeight: "50vh" }}>
				<Box
					sx={{
						width: { xs: "100%", sm: "50%" },
					}}
				>
					<Typography variant="h1" sx={{ mb: 2 }}>
						Helping You Achieve Healthcare Happiness
					</Typography>

					<Box>
						<GradientHorizontal width={"4rem"} height={".25rem"}></GradientHorizontal>
						<Typography component="p" sx={{ py: 2 }}>
							See how easily you can optimize
							<br /> your health with Radius Care
						</Typography>
					</Box>
					<ConnectButton variant={"contained"} size={"large"}></ConnectButton>
					<Box sx={{ pb: 2, display: { xs: "block", md: "none" } }}>
						<AppStoreBtn></AppStoreBtn>
					</Box>
				</Box>
				<Stack
					alignItems={"center"}
					justifyContent={"center"}
					sx={{
						width: { xs: "100%", sm: "50%" },
					}}
				>
					<Box sx={{ width: "100%" }} component="img" src={"./media/images/banners/radius-care-family-cutout.png"} alt="Home Banner" />
				</Stack>
			</Stack>
		</Container>
	);
};

export default TopBanner;
