import { Box, Stack, Typography } from "@mui/material";
import GradientHorizontal from "shared/components/GradientHorizontal";

interface Props {
  e: any;
}

const FeaturesAndBenefitsCard = ({ e }: Props) => {
  return (
    <Stack
      sx={{
        borderBottom: "2px solid",
        borderColor: "gray.light",
        height: "100%",
        pb: 2,
      }}
    >
      <Box>
        <Box
          sx={{ height: "3rem", mb: 2 }}
          component="img"
          src={e[0]}
          alt={e[1]}
        />
      </Box>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {e[1]}
      </Typography>
      <GradientHorizontal width={"4rem"} height={".25rem"} />
      <Typography component="p" sx={{ mt: 2 }}>
        {e[2]}
      </Typography>
    </Stack>
  );
};

export default FeaturesAndBenefitsCard;
