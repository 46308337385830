import { Box, List, ListItem, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Helmet } from "react-helmet";
import GradientHorizontal from "shared/components/GradientHorizontal";
import PageContainer from "shared/components/PageContainer";

const AnnUnitedHealth = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>Radius Care | Announcement - Welcome UnitedHealthcare</title>
      </Helmet>
      <Container maxWidth="sm">
        <Box sx={{ pb: 4 }}>
          <Typography variant="h1" sx={{ pb: 2 }}>
            Radius Care is now available to 45 million UnitedHealthcare
            customers
          </Typography>
          <GradientHorizontal
            width={"4rem"}
            height={".25rem"}
          ></GradientHorizontal>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 700 }}>By Derek Moore</Typography>
          <Typography>
            Radius Care has partnered with one of the largest health insurers in
            America, UnitedHealth Group, to make their tech-driven healthcare
            solutions available to more Americans.
          </Typography>
          <Typography>
            Radius Care users must connect the app with their insurer's
            databases. So, partnering with UnitedHealthcare is a huge leap
            towards being able to help millions more Americans get the
            healthcare they deserve.
          </Typography>
          <Typography>
            UnitedHealth Group is on a journey to improve healthcare. They want
            to make a difference in society. “These values resonate with us so
            much. It’s important we reach as many people as possible to help
            them find and achieve better healthcare results” says Justin Huddy,
            CEO of Radius Care.
          </Typography>
          <Typography>
            The connection with United Healthcare gives their 45 million
            customers instant free access to the Radius Care platform.
          </Typography>
          <Typography>
            New Radius Care users insured with UnitedHealthcare can connect in 3
            simple steps:
          </Typography>
          <Typography>
            <List sx={{ pl: 6, listStyleType: "decimal" }}>
              <ListItem sx={{ display: "list-item" }}>
                Set up a Radius Care Profile
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Connect to your UnitedHealthcare account
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Let the app automatically sync medical records and provide
                personalized healthcare recommendations
              </ListItem>
            </List>
          </Typography>
          <Typography>
            Discover more about the UnitedHealth Group and its mission here. Or
            sign up free to Radius Care today.
          </Typography>
        </Box>
      </Container>
    </PageContainer>
  );
};

export default AnnUnitedHealth;
