import { Container } from "@mui/material";

type Props = React.PropsWithChildren<{}>;

function AccordianContainer({ children }: Props) {
  return (
    <Container
      maxWidth="md"
      sx={{
        mt: 8,
        "& .MuiAccordion-root, & .MuiAccordion-root:first-of-type, & .MuiAccordion-root:last-of-type":
          { mb: 2, borderRadius: "1rem", boxShadow: 0 },
        "& .MuiAccordion-root:before": { display: "none" },
        "& .MuiAccordion-root.Mui-expanded": { mt: 0 },
        "& .MuiAccordionSummary-root": { minHeight: "2rem", p: 2 },
        "& .MuiAccordionSummary-root.Mui-expanded": { minHeight: "2rem" },
        "& .MuiAccordionSummary-content": { m: 0 },
        "& .MuiAccordionSummary-content.Mui-expanded": { m: 0 },
        "& .MuiAccordionSummary-expandIconWrapper > img": {
          transform: "rotate(90deg)",
          height: { xs: "1.25rem", sm: "1.5rem", md: "2rem" },
        },
      }}
    >
      {children}
    </Container>
  );
}

export default AccordianContainer;
