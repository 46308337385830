import { Box, List, ListItem, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Helmet } from "react-helmet";
import GradientHorizontal from "shared/components/GradientHorizontal";
import PageContainer from "shared/components/PageContainer";

const PrNov2022 = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>
          Radius Care | Radius Care Celebrates Newest Software Release
        </title>
      </Helmet>
      <Container maxWidth="sm">
        <Box sx={{ pb: 4 }}>
          <Typography variant="h1" sx={{ pb: 2 }}>
            Radius Care Celebrates Newest Software Release Set to Transform
            Healthcare Data Management – Radius Care 2.0
          </Typography>
          <GradientHorizontal
            width={"4rem"}
            height={".25rem"}
          ></GradientHorizontal>
        </Box>
        <Box>
          <Typography>
            SEA GIRT, N.J., November 1, 2022 – Radius Care announced the release
            of Radius Care 2.0, the latest version of its disruptive personal
            healthcare maintenance software. Radius Care is a consumer
            application that proactively informs and guides members to better
            health decisions.
          </Typography>
          <Typography>
            Radius Care has developed one of the most robust data ingestion
            engines in existence with capabilities to process millions of
            healthcare transactions seamlessly. The platform actively monitors
            health data as it changes. Using anonymized outcomes-based data from
            previous successful treatments, Radius Care will suggest top-rated
            physicians, facilities, and available services whenever a change is
            detected in a person’s health profile. The platform helps people
            understand their health and recover faster by providing:
          </Typography>
          <Typography>
            <List sx={{ listStyleType: "disc", pl: 6 }}>
              <ListItem sx={{ display: "list-item" }}>
                All health records streamlined and optimized in one place: care
                teams, medications, visits, procedures, etc.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Monetarization of patient health, providing automatic alerts
                with better options and treatment plans located near the user.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Ongoing savings by sending users alerts on medication discounts.
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Simplified healthcare views based on unique health profiles,
                providing expert guidance, and helping people live more
                fulfilled lives with smarter healthcare decisions.
              </ListItem>
            </List>
          </Typography>
          <Typography>
            "Aside from it naturally being a consumer healthcare tool,” said
            Derek Moore, Radius Care CRO. “There are also so many opportunities
            for health insurers, hospital health systems, and other managed care
            providers to derive great benefit from it. This is the future of
            healthcare."
          </Typography>
          <Typography>
            Featuring a powerful machine learning engine programmed to identify
            population risk patterns and personalized engagement opportunities,
            Radius Care orchestrates member engagement messaging through the
            platform with advanced data modeling built upon millions of
            healthcare datapoints. "We have built a secure data warehouse with
            the power to store and process volumes of records in mere seconds,"
            said Justin Huddy. “Additionally, the Radius Care Data Analytics
            Engine provides healthcare plans and providers with actionable
            insights that are programmed to identify population risk and health
            engagement opportunities.”
          </Typography>
          <Typography>
            The Company is growing rapidly and is scaling to support over one
            million lives in the next calendar year. Having reconfigured Radius
            Care to work with all major insurance companies, the platform is no
            longer a solution only for Medicare. Now people insured with the
            largest health insurance providers can connect and get the benefits
            of Radius Care for free.
          </Typography>
          <Typography>
            Consumerism in Healthcare is a movement to make the delivery of
            healthcare services more efficient and cost-effective for the
            consumer, but it is more than just showing a receipt of past
            services. To truly help guide consumers, it needs to come from
            within each person’s unique health record so that care choices can
            be optimized based on specific needs. That’s what Radius Care does
            automatically once an account is connected.
          </Typography>
          <Typography>
            "Consumerism in healthcare is a hard thing to get right; we’re proud
            to be the only solution of our type. We have been a key force in the
            Consumer Healthcare space since its infancy and its only through our
            learnings, over 10+ years, that we understand the nuances which lead
            us to this recent build”. Said founder, Justin Huddy.
          </Typography>
          <Typography>
            For more detailed platform specs and information on other healthcare
            benefits of using Radius Care, visit www.radiuscare.com or follow
            Radius Care on social media: Facebook, Instagram, LinkedIn.
          </Typography>
          <Typography>
            About Radius Care 2.0 is the only CMS-approved solution that
            provides users and their families with suggestions for the
            highest-rated physicians, facilities, and services whenever a
            patient's health change occurs. Radius Care uses Artificial
            Intelligence to review historical healthcare data points and
            transactions to deliver personalized healthcare recommendations
            based on a users' unique health needs and locations. Patients,
            caregivers, and families can now access the facts and resources they
            need to make informed healthcare decisions when it matters most.
            Learn more at: www.RadiusCare.com
          </Typography>
          <Typography>
            <strong>Media Contact:</strong>
            <br />
            Vanessa Brand
            <br />
            732-337-1520
            <br />
            Vbrand@radiuscare.com
            <br />
          </Typography>
        </Box>
      </Container>
    </PageContainer>
  );
};

export default PrNov2022;
