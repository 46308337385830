import { PaletteMode, ThemeProvider, createTheme } from "@mui/material";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import React, { useState, useMemo } from "react";
import { getDesignTokens } from "theme";

import Shell from "./layout/Shell";
import {
	Home,
	About,
	News,
	Contact,
	HowItWorks,
	PrivacyPolicy,
	TermsOfService,
	FAQs,
	Resources,
	PrNov2022,
	ArtOct2022,
	ArtNov2022,
	AnnDoximity,
	AnnSingleCare,
	AnnAetna,
	AnnMedicare,
	AnnUnitedHealth,
	ConsumerPortalRedirect,
} from "./pages/index";
import TagManager from "react-gtm-module";
import { getRuntimeConfig } from "config";
import { SnackbarProvider } from "notistack";

const tagManagerArgs = {
	gtmId: "GTM-P3MSVWG",
};

TagManager.initialize(tagManagerArgs);

const App: React.FC = () => {
	let rtc = getRuntimeConfig();
	console.debug(rtc);
	const [themeMode, setThemeMode] = useState<PaletteMode>("light");
	const theme = useMemo(() => createTheme(getDesignTokens(themeMode)), [themeMode]);

	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<SnackbarProvider autoHideDuration={2000}>
					<Shell setThemeMode={setThemeMode} themeMode={themeMode}>
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/about" element={<About />} />
							<Route path="/news" element={<News />} />
							<Route path="/press-releases/radius-care-celebrates-newest-software-release" element={<PrNov2022 />} />
							<Route path="/articles/new-diagnosis-what-to-do-next-3-easy-steps" element={<ArtNov2022 />} />
							<Route path="/articles/how-to-save-on-your-prescription-medication-costs-with" element={<ArtOct2022 />} />
							<Route path="/announcements/singlecare-partnership" element={<AnnSingleCare />} />
							<Route path="/announcements/doximity-partnership" element={<AnnDoximity />} />
							<Route path="/announcements/welcome-unitedhealth" element={<AnnUnitedHealth />} />
							<Route path="/announcements/welcome-medicare" element={<AnnMedicare />} />
							<Route path="/announcements/welcome-aetna" element={<AnnAetna />} />

							<Route path="/contact" element={<Contact />} />
							<Route path="/how-it-works" element={<HowItWorks />} />
							<Route path="/faqs" element={<FAQs />} />
							<Route path="/resources" element={<Resources />} />
							<Route path="/privacy-policy" element={<PrivacyPolicy />} />
							<Route path="/terms-of-service" element={<TermsOfService />} />

							<Route path="/consumer-portal-redirect" element={<ConsumerPortalRedirect />} />
						</Routes>
					</Shell>
				</SnackbarProvider>
			</BrowserRouter>
		</ThemeProvider>
	);
};

export default App;
