import { Container, Grid, Typography } from "@mui/material";
import { FeaturesAndBenefitsCard } from "./index";
import Icon1 from "../../../assets/icons/folder.svg";
import Icon2 from "../../../assets/icons/stats.svg";
import Icon3 from "../../../assets/icons/medical-result.svg";
import Icon4 from "../../../assets/icons/lifesaver.svg";
import Icon5 from "../../../assets/icons/open-book.svg";
import Icon6 from "../../../assets/icons/wallet.svg";
import GradientHorizontal from "shared/components/GradientHorizontal";

const FeaturesAndBenefits = () => {
  const content = [
    [Icon1, "Simplifying", "Understanding healthcare is less confusing"],
    [Icon2, "Monitoring", "Automatic alerts for better healthcare options"],
    [Icon3, "Reporting", "Your own unique health report"],
    [Icon4, "Supporting", "Make better healthcare decisions"],
    [Icon5, "Educating", "Facts from trusted & reliable sources"],
    [Icon6, "Saving", "Spend less on healthcare and more on what you love"],
  ];

  return (
    <Container maxWidth="md" sx={{ pb: 16 }}>
      <GradientHorizontal width={"4rem"} height={".25rem"} />
      <Typography sx={{ color: "gray.main", mt: 2 }}>
        Radius Care - Features
      </Typography>
      <Typography variant="h2" sx={{ pb: 8 }}>
        Say Goodbye to <br />
        Internet Searching
      </Typography>

      <Grid container spacing={12} justifyContent="center">
        {content.map((e, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <FeaturesAndBenefitsCard e={e} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default FeaturesAndBenefits;
