import { Box, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Steps from "pages/howItWorks/components/Steps";
import { Helmet } from "react-helmet";
import ConnectButton from "shared/components/ConnectButton";
import PageContainer from "shared/components/PageContainer";

const ConsumerPortalRedirect = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>Radius Care | Consumer Portal Redirect</title>
      </Helmet>
      <Stack justifyContent="center" alignItems="center" sx={{ p: 4 }}>
        <Typography variant="h1">Welcome to Radius 2.0</Typography>
      </Stack>
      <Container maxWidth="sm">
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            Please create your account by clicking on the button below
          </Typography>
          <ConnectButton variant={"contained"} size={"large"}></ConnectButton>
        </Box>
      </Container>
      <Container maxWidth="md">
        <Steps></Steps>
      </Container>
    </PageContainer>
  );
};

export default ConsumerPortalRedirect;
