import { Box, List, ListItem, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Helmet } from "react-helmet";
import GradientHorizontal from "shared/components/GradientHorizontal";
import PageContainer from "shared/components/PageContainer";

const AnnMedicare = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>Radius Care | Announcement - Welcome Medicare</title>
      </Helmet>
      <Container maxWidth="sm">
        <Box sx={{ pb: 4 }}>
          <Typography variant="h1" sx={{ pb: 2 }}>
            Medicare members get instant access to better healthcare with Radius
            Care
          </Typography>
          <GradientHorizontal
            width={"4rem"}
            height={".25rem"}
          ></GradientHorizontal>
        </Box>
        <Box>
          <Typography sx={{ fontWeight: 700 }}>By Derek Moore</Typography>
          <Typography>
            Radius Care has partnered with CMS (Centers for Medicare and
            Medicaid Services) to offer their 35 million members free healthcare
            benefits inside the Radius Care app.
          </Typography>
          <Typography>
            People over 65 years of age, tend to have more healthcare needs. So,
            getting the right information about new diagnoses, the best prices
            on prescriptions, and the optimal doctors and homecare options for
            their conditions is even more important.
          </Typography>
          <Typography>
            Now, Medicare users can get access to all that and more by signing
            up free on the Radius Care app.
          </Typography>
          <Typography>
            Tom, a Radius Care users says: “As active adults it is important to
            continue independent healthcare choices and self-reliance. Before
            Radius Care, my wife and I were constantly searching the internet
            for health provider performance and information related to any
            diagnosis or prescription. All this information is now centralized
            in Radius Care. I especially trust Radius Care’s information because
            it is linked to our Medicare data and is based on our outcome
            information. They also pull information from the National Library of
            Medicine.
          </Typography>
          <Typography>
            Medicare Members can connect their medical records to Radius Care
            securely in 3 simple steps:
          </Typography>
          <Typography>
            <List sx={{ pl: 6, listStyleType: "decimal" }}>
              <ListItem sx={{ display: "list-item" }}>
                Set up a Radius Care Profile
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Connect to your Medicare account
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Let the app automatically sync medical records and provide
                personalized healthcare recommendations
              </ListItem>
            </List>
          </Typography>
          <Typography>
            Discover more about Medicare here. Or sign up free to Radius Care
            today.
          </Typography>
        </Box>
      </Container>
    </PageContainer>
  );
};

export default AnnMedicare;
