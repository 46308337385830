import { Box, FormControl, InputLabel, Select, MenuItem, TextField, Button } from "@mui/material"

interface Props {
    setSearchBar: any
    searchBar: any
}

const SearchBar = ({ searchBar}: Props) => {

    return (
        <Box sx={{ width: "100%", height: "10vh", display: searchBar ? "none" : "flex", justifyContent: "center", alignItems: "center", padding: "5px 0", backgroundColor: "background.default", borderTop: 2, borderColor: "gray.main"}}>
            <Box sx={{display: "flex", flexDirection:"row", justifyContent:"space-around", alignItems: "center", width: {xs: "100%", md:"60%"}, }}> 
                <FormControl variant="standard" sx={{width: {xs: "30%", md: "25%"}}}>
                    <InputLabel>Search for?</InputLabel>
                    <Select
                        label="Search for?"
                        value=""
                        // onChange={handleChange}
                    >
                        <MenuItem value={"Provider"}>Provider</MenuItem>
                        <MenuItem value={"Doctor"}>Doctor</MenuItem>
                        <MenuItem value={"Pharmacy"}>Phamacy</MenuItem>
                    </Select>
                </FormControl>
                <TextField id="standard-basic" label="Location" variant="standard" sx={{width: {xs: "30%", md: "50%"}}}/>
                <Button variant='contained' sx={{ borderRadius: 56, paddingLeft: "2vw", paddingRight: "2vw", background: "primary.main"}} >Search</Button >
            </Box>
        </Box>
    )
}

export default SearchBar