// import { apiRequest } from "./api/client";

function create_env_config(runtime: any) {
	console.groupCollapsed("create_env_config() " + process.env.REACT_APP_INSTANCE);
	console.debug("process.env:", process.env);
	console.debug("runtime", runtime);
	console.debug("REACT_APP_INSTANCE:", process.env.REACT_APP_INSTANCE);
	console.groupEnd();

	const env_config = {
		REACT_APP_API_BASEURL: "",
	};
	switch (runtime.ENVIRONMENT_NAME) {
		case "Production":
			env_config.REACT_APP_API_BASEURL = process.env.REACT_APP_API_BASEURL_PRODUCTION as string;
			break;
		case "Staging":
			env_config.REACT_APP_API_BASEURL = process.env.REACT_APP_API_BASEURL_STAGING as string;
			break;
		case "Development":
			env_config.REACT_APP_API_BASEURL = process.env.REACT_APP_API_BASEURL_DEVELOPMENT as string;
			break;
		default:
			if (process.env.REACT_APP_API_BASEURL_FORCE) {
				env_config.REACT_APP_API_BASEURL = process.env.REACT_APP_API_BASEURL_FORCE;
			} else {
				env_config.REACT_APP_API_BASEURL = process.env.REACT_APP_API_BASEURL_LOCAL as string;
			}
			break;
	}
	return env_config;
}

var env_config: any;

type APP_CONFIG = {
	APIConfig: {
		BaseUrl: string;
	};
	Environment: {
		ENVIRONMENT_NAME: string | undefined;
	};
};

function getRuntimeConfig(): APP_CONFIG {
	if (config) {
		return config;
	}

	const runtime = (globalThis as any)?.["__RUNTIME_CONFIG"] ?? {};
	if (!env_config) {
		env_config = create_env_config(runtime);
	}

	return {
		APIConfig: {
			BaseUrl: runtime.API_BASEURL ?? env_config.REACT_APP_API_BASEURL,
		},
		Environment: {
			ENVIRONMENT_NAME: runtime.ENVIRONMENT_NAME,
		},
	};
}

var config = getRuntimeConfig();

const getAppBaseUrl = async () => {
	let origin = window.location.origin;
	// if (origin.indexOf("localhost") !== -1) {
	//   // This is for development essentially - no need to hardcode personal IPs - if origin is localhost replace with the current actual IP address of the client
	//   let details = await apiRequest({ url: "http://ip-api.com/json" });
	//   if (typeof details === "object" && (details as any)?.query) {
	//     origin = origin.replace("localhost", (details as any)?.query);
	//   }
	// }
	return origin;
};

const local_origin = async () => {
	let o = !getRuntimeConfig().Environment.ENVIRONMENT_NAME ? await getAppBaseUrl() : undefined;
	console.debug("local_origin", o);
	return o;
};

export { getAppBaseUrl, getRuntimeConfig, local_origin, config };
