import { PaletteMode } from "@mui/material";
import { green, purple, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const defaults = createTheme();

export const getDesignTokens = (mode: PaletteMode) => ({
  zindex: {
    drawer: 1010,
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 1200,
      lg: 1500,
      xl: 1900,
    },
  },

  components: {
    /*! buttons */
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "4.5rem",
        },
        outlined: {
          border: "2px solid #e7eeee",
          color: "#2c2e3b",
          "&:hover": {
            border: "2px solid #e7eeee",
          },
        },
        sizeLarge: {
          minHeight: "3.5rem",
          minWidth: "15rem",
          fontSize: "1.25rem",
        },
      },
    },

    /*! typography */
    MuiTypography: {
      styleOverrides: {
        h1: {
          [defaults.breakpoints.up("xs")]: {
            fontSize: "2rem",
          },
          [defaults.breakpoints.up("md")]: {
            fontSize: "3rem",
          },
          [defaults.breakpoints.up("xl")]: {
            fontSize: "3.5rem",
          },
          fontWeight: 700,
        },
        h2: {
          [defaults.breakpoints.up("xs")]: {
            fontSize: "1.75rem",
          },
          [defaults.breakpoints.up("md")]: {
            fontSize: "2.5rem",
          },
          [defaults.breakpoints.up("xl")]: {
            fontSize: "3rem",
          },
          fontWeight: 700,
        },
        h3: {
          [defaults.breakpoints.up("xs")]: {
            fontSize: "1.5rem",
          },
          [defaults.breakpoints.up("md")]: {
            fontSize: "2rem",
          },
          [defaults.breakpoints.up("xl")]: {
            fontSize: "2.5rem",
          },
          fontWeight: 700,
        },
        h4: {
          [defaults.breakpoints.up("xs")]: {
            fontSize: "1.25rem",
          },
          [defaults.breakpoints.up("md")]: {
            fontSize: "1.5rem",
          },
          [defaults.breakpoints.up("xl")]: {
            fontSize: "2rem",
          },
          fontWeight: 700,
        },
        h5: {
          [defaults.breakpoints.up("xs")]: {
            fontSize: "1rem",
          },
          [defaults.breakpoints.up("md")]: {
            fontSize: "1.25rem",
          },
          [defaults.breakpoints.up("xl")]: {
            fontSize: "1.5rem",
          },
          fontWeight: 700,
        },
        body1: {
          fontSize: "1.25rem",
          marginBottom: "1rem",
        },
      },
    },

    /*! paper */
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: "1.5rem",
        },
      },
    },

    /*! list */
    MuiList: {
      styleOverrides: {
        root: {
          fontSize: "1.25rem",
          "&.bullet-list": {
            paddingLeft: "1rem",
          },
        },
      },
    },

    /*! card */
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": { paddingBottom: "1rem" },
        },
      },
    },
  },

  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          common: {
            black: "#000",
            white: "#fff",
          },
          gray: {
            light: "#e7eeee",
            main: "#80818a",
            dark: "#848484b6",
            darker: "#3A3A3A",
            contrastText: "#3A3A3A",
          },
          primary: {
            main: "#000a6d",
            light: "#f4f4fa",
            dark: "#02024c",
            xdark: "#30313b",
            contrastText: "#fff",
          },
          secondary: {
            main: "#FBBE28",
            light: "#faf7f0",
            dark: "#fb5628",
            contrastText: "#fff",
          },
          error: {
            main: "#d32f2f",
            light: "#ef5350",
            dark: "#c62828",
            contrastText: "#fff",
          },
          warning: {
            main: "#ed6c02",
            light: "#ff9800",
            dark: "#e65100",
            contrastText: "#fff",
          },
          info: {
            main: "#0288d1",
            light: "#03a9f4",
            dark: "#01579b",
            contrastText: "#fff",
          },
          success: {
            main: "#2e7d32",
            light: "#4caf50",
            dark: "#1b5e20",
            contrastText: "#fff",
          },
          background: {
            paper: "#ffffff",
            default: "#fff",
          },
          text: {
            primary: "rgba(0,0,0,0.87)",
            secondary: "rgba(0,0,0,0.6)",
            disabled: "rgba(0,0,0,0.38)",
          },
          transitions: {
            easing: {
              easeInOut: "cubic-bezier(0.4,0,0.2,1)",
              easeOut: "cubic-bezier(0.0,0,0.2,1)",
              easeIn: "cubic-bezier(0.4,0,1,1)",
              sharp: "cubic-bezier(0.4,0,0.,6)",
            },
            duration: {
              shortest: 150,
              shorter: 200,
              short: 250,
              standart: 300,
              complex: 375,
              enteringScreen: 225,
              leavingScreen: 195,
            },
          },
        }
      : {
          // palette values for dark mode
          primary: purple,
          divider: red[700],
          background: {
            default: red[900],
            paper: green[900],
          },
          text: {
            primary: "#fff",
            secondary: red[500],
          },
        }),
  },
});
