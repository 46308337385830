import { Box, Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import GradientHorizontal from "shared/components/GradientHorizontal";
import ArticleCard from "../components/ArticleCard";

const PressReleases = () => {
  return (
    <Box>
      <Helmet>
        <title>Radius Care | Press Releases</title>
      </Helmet>
      <Box sx={{ pb: 4 }}>
        <Typography variant="h3" sx={{ pb: 2 }}>
          Press Releases
        </Typography>
        <GradientHorizontal
          width={"4rem"}
          height={".25rem"}
        ></GradientHorizontal>
      </Box>
      <Grid spacing={4} container>
        <Grid item xs={12} sm={6} md={4}>
          <ArticleCard
            image={"./media/images/prs/PR-Nov-1-2022.png"}
            title={"Radius Care Celebrates Newest Software Release"}
            date={"November 1, 2022"}
            target={"_blank"}
            path={
              "/press-releases/radius-care-celebrates-newest-software-release"
            }
          ></ArticleCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ArticleCard
            image={"./media/images/prs/PR-Mar-16-2022.png"}
            title={
              "Podcast: Delivering Customers With Accurate and Trustworthy Healthcare"
            }
            date={"March 16, 2022"}
            target={"_blank"}
            path={
              "https://sites.libsyn.com/163898/justin-huddy-helps-save-lives-by-delivering-customers-with-accurate-and-trustworthy-healthcare-information-on-time"
            }
          ></ArticleCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ArticleCard
            image={"./media/images/prs/PR-Feb-17-2022.png"}
            title={
              "Justin Huddy, CEO and Founder of Radius Care, on Managing Care for Someone You Love"
            }
            date={"February 17, 2022"}
            target={"_blank"}
            path={
              "https://gritdaily.com/justin-huddy-ceo-and-founder-of-radius-care-on-managing-care-for-someone-you-love/"
            }
          ></ArticleCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ArticleCard
            image={"./media/images/prs/PR-Feb-07-2022.png"}
            title={
              "Radius Care Expands Offerings Through Its Free Healthcare App for Medicare Patients and Their Families"
            }
            date={"Feb 07, 2022"}
            target={"_blank"}
            path={
              "https://www.prnewswire.com/news-releases/radius-care-expands-offerings-through-its-free-healthcare-app-for-medicare-patients-and-their-families-301476093.html"
            }
          ></ArticleCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ArticleCard
            image={"./media/images/prs/PR-Apr-1-2020.png"}
            title={
              "Radius Care Inc. Provides Answers During National Health Crisis"
            }
            date={"Apr 01, 2020"}
            target={"_blank"}
            path={
              "https://www.prnewswire.com/news-releases/radius-care-inc-provides-answers-during-national-health-crisis--individualized-healthcare-solutions-developed-to-help-seniors-seeking-answers-to-health-challenges-301033149.html"
            }
          ></ArticleCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ArticleCard
            image={"./media/images/prs/PR-Mar-12-2020.png"}
            title={
              "Radius Care Inc. Revolutionizing Healthcare Industry with Free Innovative Health Record Monitoring Solution"
            }
            date={"March 12, 2020"}
            path={
              "https://www.benzinga.com/pressreleases/20/03/ab15543907/radius-care-inc-revolutionizing-healthcare-industry-with-free-innovative-health-record-monitoring"
            }
            target={"_blank"}
          ></ArticleCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <ArticleCard
            image={"./media/images/prs/PR-Feb-20-2020.png"}
            title={
              "Stop Searching the Web for Accurate Healthcare Answers & Get the Facts"
            }
            date={"February 20, 2020"}
            target={"_blank"}
            path={
              "https://medhealthoutlook.com/stop-searching-the-web-for-accurate-healthcare-answers-get-the-facts/"
            }
          ></ArticleCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PressReleases;
